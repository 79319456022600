import { slice } from '../store/whiteboard/slice'

export type ResultAndWarningsResponse = {
  result: boolean
  warnings: {
    message: string
  }[]
}

export type SelectedSwitchType = 'whiteboard' | 'template'

export type VisibleWhiteboardsParams = {
  type?: SelectedSwitchType
  start: number
  rows: number
  sort?: string
  release?: string
  showFirstLastPinned?: boolean
  skuLastPinned?: string
  searchText?: string
  eventId: string
  customerId: string
}

export type WhiteboardState = ReturnType<typeof slice.reducer>

export type WhiteboardCardType = {
  key: string
  id: string
  name: string
  release: string
  sports: string[]
  subcategories: string[]
  owner: string
  isOwner: boolean
  creationDate: string
  dateLastPinned: string
  timestampLastPinned: number
  locked: boolean
  lastUpdate: string
  numberOfProducts: number
  previewProducts?: string
  type: SelectedSwitchType
}

export type BoardType = {
  copyOfKey?: string
  key: string
  name: string | null
  items: WhiteboardProductType[]
  images: WhiteboardItemType[]
  texts: WhiteboardItemType[]
  showProductName?: boolean
  showProductNumber?: boolean
  showColorName?: boolean
  showColorCode?: boolean
  showWholesale?: boolean
  showSuggested?: boolean
  showItemInCart: boolean
  labelPosition: string
  isNew?: boolean
}

export const releases = ['FW', 'SS'] as const
export type Release = typeof releases[number]

export type WhiteboardType = {
  owner?: string
  name: string
  key: string
  release: Release
  previewProducts?: string
  boards: BoardType[]
  locked?: boolean
}

export type WhiteboardResponseType = {
  whiteboards: WhiteboardCardType[]
  lastPinnedWhiteboard: {
    key: string
    name: string
  }
  total: number
}

type link = string
type cssId = string
export type WhiteboardLinks = Record<cssId, link>

export type DuplicateWhiteboard = {
  eventCode: string
  customerCode: string
  keyToCopy: string
  newName: string
  type?: SelectedSwitchType
  owner: string
}

export type ShareTemplate = {
  eventCode: string
  customerCode: string
  key: string
  kamsToShare: string[]
  pdf64: string
}

export type WhiteboardPinItem = {
  eventCode: string
  customerCode: string
  key: string
  productNumber: string
  productName: string
  colorCode: string
  colorName: string
  image: string
  pinned: boolean
  materialCode: string
}

export type PinnedItemsToWhiteboardType = {
  productNumber: string
  productName: string
  colorCode: string
  colorName: string
  image: string
  materialCode: string
  invalid: true | ''
}

export type PinnedItemsToWhiteboardResponse = {
  items: PinnedItemsToWhiteboardType[]
}

export type GetKamsResponse = {
  kams: {
    guidUserProfile: string
    userName: string
    email: string
    upn: string
  }[]
}

export type WhiteboardDownloadModalType = {
  setDownloadModalOpen: (e: boolean) => void
  name: string
  id: string
}

export type ProductSettingsType = {
  position: {
    x: number
    y: number
  }
  style: {
    zIndex: number
    width: number
    height: number
  }
}

export type ProductType = {
  productName: string
  productNumber: string
  colorName: string
  colorCode: string
  wholesalePrice: number | null
  suggestedPrice: number | null
  image: string
  category: string
  subCategory: string
  itemInCart: boolean
  materialCode: string
  invalid?: true | null
}

export const itemValues = ['items', 'texts', 'images'] as const
export type ItemType = typeof itemValues[number]

export type WhiteboardItemTypeSettings = {
  position: {
    x: number
    y: number
  }
  style: {
    zIndex: number
    width: number
    height: number
    fontSize?: number
  }
}

export type WhiteboardItemType = {
  id: string
  selected?: boolean
  product?: ProductType
  settings: WhiteboardItemTypeSettings
  value: string
  type: ItemType
  isNew?: boolean
}

export type WhiteboardProductType = WhiteboardItemType & {
  product: ProductType
}

export const alignValues = [
  'left',
  'right',
  'horizontal center',
  'top',
  'bottom',
  'vertical center',
] as const
export type align = typeof alignValues[number]

export const distributeValues = ['horizontally', 'vertically'] as const
export type distribute = typeof distributeValues[number]

export const PAGE_SIZES = {
  '4A0': [4767.87, 6740.79],
  '2A0': [3370.39, 4767.87],
  A0: [2383.94, 3370.39],
  A1: [1683.78, 2383.94],
  A2: [1190.55, 1683.78],
  A3: [841.89, 1190.55],
  A4: [595.28, 841.89],
  A5: [419.53, 595.28],
  A6: [297.64, 419.53],
  A7: [209.76, 297.64],
  A8: [147.4, 209.76],
  A9: [104.88, 147.4],
  A10: [73.7, 104.88],
  B0: [2834.65, 4008.19],
  B1: [2004.09, 2834.65],
  B2: [1417.32, 2004.09],
  B3: [1000.63, 1417.32],
  B4: [708.66, 1000.63],
  B5: [498.9, 708.66],
  B6: [354.33, 498.9],
  B7: [249.45, 354.33],
  B8: [175.75, 249.45],
  B9: [124.72, 175.75],
  B10: [87.87, 124.72],
  C0: [2599.37, 3676.54],
  C1: [1836.85, 2599.37],
  C2: [1298.27, 1836.85],
  C3: [918.43, 1298.27],
  C4: [649.13, 918.43],
  C5: [459.21, 649.13],
  C6: [323.15, 459.21],
  C7: [229.61, 323.15],
  C8: [161.57, 229.61],
  C9: [113.39, 161.57],
  C10: [79.37, 113.39],
  RA0: [2437.8, 3458.27],
  RA1: [1729.13, 2437.8],
  RA2: [1218.9, 1729.13],
  RA3: [864.57, 1218.9],
  RA4: [609.45, 864.57],
  SRA0: [2551.18, 3628.35],
  SRA1: [1814.17, 2551.18],
  SRA2: [1275.59, 1814.17],
  SRA3: [907.09, 1275.59],
  SRA4: [637.8, 907.09],
  EXECUTIVE: [521.86, 756.0],
  FOLIO: [612.0, 936.0],
  LEGAL: [612.0, 1008.0],
  LETTER: [612.0, 792.0],
  TABLOID: [792.0, 1224.0],
  ID1: [153, 243],
}

//export const sidebarWidth = clientWidth > 768 ? 138 : 120
//  width: ${gF('px', 426, 926, 1366, 3840)};

export enum ProductsListWidth {
  'MOBILE' = 240,
  'DESKTOP' = 426,
  '4K' = 925,
}

export const getProductsListWidth = () => {
  const { clientWidth } = window.document.body
  if (clientWidth <= 768) {
    return ProductsListWidth.MOBILE
  }
  if (clientWidth > 768 && clientWidth < 3800) {
    return ProductsListWidth.DESKTOP
  }
  if (clientWidth >= 3800) {
    return ProductsListWidth['4K']
  }
  return 0
}
export const productsListWidth = getProductsListWidth()
export const categories = ['pinned', 'apparel', 'footwear', 'accessories']
export const categoriesOrders = ['apparel', 'footwear', 'accessories', 'goggles', 'helmets']
export const sortWhiteboardCategoriesFunction = (
  category1: { name: string },
  category2: { name: string },
) => {
  return (
    categoriesOrders.indexOf(category1.name.toLowerCase()) -
    categoriesOrders.indexOf(category2.name.toLowerCase())
  )
}

export type SORTING_CRITERIA = 'date' | 'name'
