import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import SwiperCore, { Navigation, Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import noBackground from '../../components/icons/noBackground.svg'
import AfaAdvChip from '../../components/AfaAdvChip'
import { afaProductCardChipCss } from '../../components/CommonComponents'
import ChevronLeftIcon from '../../components/icons/ChevronLeftIcon'
import ChevronRightIcon from '../../components/icons/ChevronRightIcon'
import { getDeviceAbsolutePath } from '../../libs/url'
import { AfaMoco, AfaProduct } from '../../model/afa'
import { useGetTechnologyIconsQuery } from '../../services/afaContents'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import SwiperStyle from '../../style/SwiperStyle'
import { getLowestPriceFromMocos } from '../../libs'
import { useGetBrandsQuery } from '../../services/afa'
import RCIcon from '../../components/UI/RCIcon'
import {
  CheckedIcon,
  PinButton,
  Pinned,
  Title,
  TitleWrapper,
} from '../AfaKeylooks/AfaKeylooksProduct'
import { useDispatch, useSelector } from 'react-redux'
import { itemsPinModalSelector, openPinModalSelector } from '../../store/whiteboard/selectors'
import whiteboardActions from '../../store/whiteboard/actions'
import { generateSkuCode, generateSkuCodeAfaMoco } from '../../libs/whiteboard'
import {
  customerNationalitySelector,
  showSrPriceSelector,
  showWhsPriceSelector,
} from '../../store/customer/selectors'
import { getProductImageUrl } from '../../libs/productImages'
import { useGetKeylookUrlWithState } from '../../hooks/useGetKeylookUrlWithState'
import { useLocationState } from '../../hooks/useLocationState'
import { localesUtils } from '../../libs/locales'

const flexCenterBetween = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`

SwiperCore.use([Navigation, Thumbs])

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${pxToRem(24)}rem;
  background-color: ${palette.white};
  color: ${palette.tangaroa};
  height: 100%;
  width: 100%;
`

const SwiperWrapper = styled.div`
  .swiper {
    width: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: ${pxToRem(18)}rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .swiper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .swiperBigImage {
    width: 100%;
    margin-bottom: ${getFluidSizeWithFullFormula('px', 12, 30, 1366, 3840)};

    a {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: ${getFluidSizeWithFullFormula('px', 177, 500, 1366, 3840)};
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .swiperNavigation {
    height: ${getFluidSizeWithFullFormula('px', 48, 118, 1366, 3840)};
    box-sizing: border-box;
    width: 80%;

    & .swiper-slide {
      width: 20%;
      height: 100%;
      opacity: 0.4;
    }

    & .swiper-slide-thumb-active {
      opacity: 1;
    }
  }
`

const SwiperNavigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const UnderHeaderInfo = styled.div`
  ${flexCenterBetween};
  text-transform: uppercase;
  font-size: ${getFluidFontSize('11px')};
`

const AdvAndPo = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToRem(spacing(1))}rem;
`

const BrandsIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 1em;
  height: ${getFluidSizeWithFullFormula('px', 14, 29, 1366, 3840)};
  margin-top: ${getFluidSizeWithFullFormula('px', 24, 41, 1366, 3840)};
  margin-bottom: ${getFluidSizeWithFullFormula('px', 24, 37, 1366, 3840)};
  place-items: start;

  @media (min-width: ${breakpointsCross.M.over}) and (max-width: ${breakpointsCross.XL.min}) {
    margin: ${pxToRem(16)}rem;
  }
`

const IconWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`

const Icon = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  display: block;
`

const ChevronLeftIconStyled = styled(ChevronLeftIcon)`
  &.swiper-button-disabled {
    visibility: hidden;
  }

  width: ${getFluidFontSize('16px')};
  height: ${getFluidFontSize('16px')};
`

const ChevronRightIconStyled = styled(ChevronRightIcon)`
  &.swiper-button-disabled {
    visibility: hidden;
  }

  width: ${getFluidFontSize('16px')};
  height: ${getFluidFontSize('16px')};
`

const Img = styled.img`
  display: block;
  position: relative;

  &:before {
    content: '';
    background-image: url('/assets/images/transparent_placeholder.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }
`
const ImgFallBack = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${palette.white};
`

const Prices = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getFluidSizeWithFullFormula('px', 10, 14, 1366, 3840)};

  @media (min-width: ${breakpointsCross.M.over}) and (max-width: ${breakpointsCross.XL.min}) {
    gap: 0;
  }
`

const PricesTextsWrapper = styled.div`
  ${flexCenterBetween};
`

const PriceText = styled.div`
  color: ${palette.santasGray};
  font-size: ${getFluidFontSize('13px')};
`

const Advs = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${getFluidFontSize('11px')};
  margin-top: 1em;
  height: 2em;
  gap: 1em;
`

const ChipLink = styled(Link)`
  ${afaProductCardChipCss};
  color: ${palette.tangaroa};
`

const WhsPrice = styled.div`
  font-size: ${getFluidFontSize('16px')};
  font-weight: bold;
`

const SrPrice = styled.div`
  font-size: ${getFluidFontSize('16px')};
  font-weight: bold;
`

const Divider = styled.div`
  background-color: ${palette.mischka};
  height: 2px;
  width: 100%;
  font-size: ${getFluidFontSize('11px')};
  margin: 2em 0;

  @media screen and (max-width: ${breakpoints.L}) {
    margin: ${pxToRem(16)}rem 0;
  }
`

type Props = {
  product: AfaProduct
  withLink?: boolean
  style?: React.CSSProperties
  currency?: string
  preselectColorCode?: string
}

const AfaPlpProductCard: React.FC<Props> = ({
  product,
  withLink,
  style,
  currency,
  preselectColorCode,
}) => {
  const { t } = useTranslation()
  const { assortment } = useParams<{ assortment: string }>()
  const locationState = useLocationState()
  const [isProductPinned, setIsProductPinned] = useState(false)
  const products = useSelector(itemsPinModalSelector)
  const dispatch = useDispatch()
  const openPinModal = useSelector(openPinModalSelector)
  const showSrPrice = useSelector(showSrPriceSelector)
  const showWhsPrice = useSelector(showWhsPriceSelector)
  const customerNationality = useSelector(customerNationalitySelector)

  const colors = Object.values(product.mocos)

  const sortedColors = [...colors].sort((a, b) => a.order - b.order)

  const preselectIndex = preselectColorCode
    ? sortedColors.findIndex(({ colorCode }) => colorCode === preselectColorCode)
    : -1
  const colorPreselectedIndex = preselectIndex !== -1 ? preselectIndex : 0

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | undefined>()
  const [activeIndex, setActiveIndex] = useState<number>(colorPreselectedIndex)
  const [activeKeylooks, setActiveKeylooks] = useState<string[]>(
    sortedColors[colorPreselectedIndex]?.keylooks || [],
  )

  const technologyIconsQuery = useGetTechnologyIconsQuery(product?.brandCodeParent)
  const technologyIcons = technologyIconsQuery.data || {
    tech: [],
    kspTechnology: [],
    kspSustainability: [],
  }

  const {
    lowestPrice: lowestWhsPrice,
    hasPriceVariation: whsPriceHasVariation,
  } = getLowestPriceFromMocos(sortedColors, 'whsPrice')
  const {
    lowestPrice: lowestSrPrice,
    hasPriceVariation: srPriceHasVariation,
  } = getLowestPriceFromMocos(sortedColors, 'srPrice')

  const selectedColor = sortedColors[activeIndex]
  const selectedColorCode = selectedColor.colorCode

  const mocoToPin: AfaProduct | undefined =
    selectedColorCode && selectedColor && product
      ? {
        ...product,
        mocos: {
          selectedColorCode: selectedColor,
        },
      }
      : undefined

  const swiperInstanceRef = useRef<SwiperCore>()

  const brandsQuery = useGetBrandsQuery()
  const defaultCurrency = brandsQuery.data?.find(
    ({ code }) => code === selectedColor?.brandCodeParent,
  )?.currency
  const selectedCurrency = currency || defaultCurrency

  useEffect(() => {
    setIsProductPinned(
      products.some(
        productPinned =>
          productPinned.modelCode === product.modelCode &&
          productPinned.name === product.name &&
          !openPinModal,
      ),
    )
  }, [products, product, openPinModal])

  const resetPinItems = (color: AfaMoco) => {
    if (
      products.length &&
      generateSkuCode([products[0]]) !== generateSkuCodeAfaMoco(color) &&
      products[0].modelCode === color.modelCode
    ) {
      dispatch(whiteboardActions.resetItemsPinModal())
    }
  }

  const keylookUrl = useGetKeylookUrlWithState(product.brandCodeParent, activeKeylooks)

  return (
    <Wrapper style={style}>
      <SwiperStyle />
      <Header>
        <TitleWrapper isProductPinned={isProductPinned}>
          <Title>{product.name.toLowerCase()}</Title>
          {mocoToPin &&
            (isProductPinned ? (
              <Pinned>
                <CheckedIcon />
                <span>{t('Whiteboard.pinned')}</span>
              </Pinned>
            ) : (
              <PinButton
                onClick={() => {
                  dispatch(whiteboardActions.resetItemsPinModal())
                  dispatch(whiteboardActions.setSelectedSkuCode(generateSkuCode([mocoToPin])))
                  dispatch(whiteboardActions.setOpenPinModal(true))
                  dispatch(whiteboardActions.setItemsPinModal([mocoToPin]))
                }}
              >
                <RCIcon type="pin" />
              </PinButton>
            ))}
        </TitleWrapper>
        <UnderHeaderInfo>
          <div>
            {product.modelCode}-{selectedColorCode}
          </div>
          <AdvAndPo>
            {selectedColor.availabilityDataList.length > 0 && (
              <div>{selectedColor.availabilityDataList[0].poCode}</div>
            )}
            {selectedColor.availabilityDataList.length > 0 && (
              <div>{selectedColor.availabilityDataList[0].avdCode}</div>
            )}
          </AdvAndPo>
        </UnderHeaderInfo>
        <BrandsIcons>
          {[...technologyIcons.kspTechnology, ...technologyIcons.kspSustainability]
            .filter(({ tag }) => sortedColors[activeIndex].technologies?.includes(tag))
            .slice(0, 3)
            .map(({ url, tag }) => (
              <IconWrapper key={tag}>
                <Icon src={url} alt="" />
              </IconWrapper>
            ))}
        </BrandsIcons>
      </Header>
      <div>
        <SwiperWrapper>
          <Swiper
            initialSlide={activeIndex}
            spaceBetween={10}
            onSwiper={swiperInstance => (swiperInstanceRef.current = swiperInstance)}
            onSlideChange={({ realIndex }) => {
              setActiveIndex(realIndex)
            }}
            thumbs={{ swiper: thumbsSwiper }}
            className="swiperBigImage"
          >
            {sortedColors.map(color => {
              if (withLink) {
                return (
                  <SwiperSlide key={color.colorCode}>
                    <Link
                      to={{
                        pathname: `${getDeviceAbsolutePath()}/${assortment}/pdp/${product.modelCode
                          }`,
                        search: `?tab=details&color=${color.colorCode}`,
                        state: locationState,
                      }}
                    >
                      {color.catalogImgPath ? (
                        <Img
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = noBackground
                          }}
                          src={getProductImageUrl({
                            path: color.catalogImgPath,
                            scale: true,
                            imwidth: 300,
                          })}
                          alt=""
                        />
                      ) : (
                        <ImgFallBack />
                      )}
                    </Link>
                  </SwiperSlide>
                )
              }
              return (
                <SwiperSlide key={color.colorCode}>
                  {color.catalogImgPath ? (
                    <Img
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = noBackground
                      }}
                      src={getProductImageUrl({
                        path: color.catalogImgPath,
                        imwidth: 300,
                        scale: true,
                      })}
                      alt=""
                    />
                  ) : (
                    <ImgFallBack />
                  )}
                </SwiperSlide>
              )
            })}
          </Swiper>
          <SwiperNavigation>
            <ChevronLeftIconStyled id={`swiper-back-${product.plpOrder}`} />
            <Swiper
              onSwiper={e => setThumbsSwiper(e)}
              spaceBetween={10}
              slidesPerView={4}
              onTouchStart={(_, event: any) => {
                const swiper = event.target.parentElement.parentElement
                const actives = swiper.getElementsByClassName('swiper-slide-thumb-active')
                actives[0]?.classList && actives[0].classList.remove('swiper-slide-thumb-active')
                event.target.parentElement.classList.add('swiper-slide-thumb-active')
              }}
              navigation={{
                nextEl: `#swiper-next-${product.plpOrder}`,
                prevEl: `#swiper-back-${product.plpOrder}`,
              }}
              freeMode={true}
              watchSlidesProgress={true}
              className="swiperNavigation"
            >
              {sortedColors.map((color, index) => (
                <SwiperSlide
                  key={color.colorCode}
                  onClick={e => {
                    e.stopPropagation()
                    setActiveIndex(index)
                    setActiveKeylooks(color.keylooks || [])
                    swiperInstanceRef.current?.slideTo(index)
                    resetPinItems(color)
                  }}
                >
                  {color.catalogImgPath ? (
                    <Img
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = noBackground
                      }}
                      src={getProductImageUrl({
                        path: color.catalogImgPath,
                        imwidth: 300,
                        scale: true,
                      })}
                      alt=""
                    />
                  ) : (
                    <ImgFallBack />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            <ChevronRightIconStyled id={`swiper-next-${product.plpOrder}`} />
          </SwiperNavigation>
        </SwiperWrapper>
        <Advs>
          <AfaAdvChip product={product} />
          {keylookUrl && <ChipLink to={keylookUrl}>{t('Afa.KeyLookChipLink')}</ChipLink>}
        </Advs>
        {(showSrPrice || showWhsPrice) && <Divider />}
        <Prices>
          <PricesTextsWrapper>
            {showWhsPrice && lowestWhsPrice !== null && (
              <PriceText>
                {t(
                  srPriceHasVariation || whsPriceHasVariation ? 'Afa.WHSPriceFrom' : 'Afa.WHSPrice',
                )}
              </PriceText>
            )}
            {showSrPrice && lowestSrPrice !== null && (
              <PriceText>
                {t(srPriceHasVariation || whsPriceHasVariation ? 'Afa.srpFrom' : 'Afa.srp')}
              </PriceText>
            )}
          </PricesTextsWrapper>
          <PricesTextsWrapper>
            {showWhsPrice && lowestWhsPrice !== null && (
              <WhsPrice>
                {localesUtils.formatCurrency(customerNationality, lowestWhsPrice, selectedCurrency)}
              </WhsPrice>
            )}
            {showWhsPrice && lowestWhsPrice !== null && showSrPrice && lowestSrPrice !== null ? ' ' : ''}
            {showSrPrice && lowestSrPrice !== null && (
              <SrPrice>
                {localesUtils.formatCurrency(customerNationality, lowestSrPrice, selectedCurrency)}
              </SrPrice>
            )}
          </PricesTextsWrapper>
        </Prices>
      </div>
    </Wrapper>
  )
}

export default AfaPlpProductCard
