import {
  AfaCartProduct,
  AfaDrop,
  AfaFacet,
  AfaMoco,
  AfaPrebook,
  AfaProduct,
  AfaSeasonSale,
  DropType,
  Option,
  UpdateAfaCartProductPayload,
} from '../model/afa'
import { DdMmYyyyDateString, Upc } from '../model/model'
import { convertDdMmYyyyToDate, sortDdMmYyyyDates } from './time'
import { isNotUndefined } from './utils'
import { sortProductsByDelivery } from './productsV2'
import { isNumber } from './numbers'
import { getAfaFilterTranslation } from '../components/AfaFilters/AfaFiltersLayer'
import { TFunction } from 'i18next'

export const getDefaultColor = (product?: AfaProduct): AfaMoco | undefined => {
  const colors = Object.values(product?.mocos || {})

  const defaultColor = colors.sort((c1, c2) => c1.order - c2.order)[0]

  return defaultColor
}

const categoriesOrder = ['goggles', 'helmets', 'apparel', 'accessories', 'footwear']
const categoriesFallbackOrder = ['apparel', 'goggles', 'helmets', 'footwear', 'accessories']
export const AFA_CART_FILTERS = Object.freeze([
  'size',
  'sport',
  'colorFacet',
  'technology',
  'gender',
  'release',
  'family',
  'productType',
  'collection',
  'limitedRelease',
  'fabric',
  'fabricGroup',
  'fit',
  'afaStyle',
  'adv',
  'avds',
  'hasKeylooks',
  'afacatalogid',
  'category',
] as const)
export type AfaCartFilter = typeof AFA_CART_FILTERS[number]

export const sortCategoriesFunction = (fallback?: boolean) => (
  category1: { name: string },
  category2: { name: string },
) => {
  const order = fallback ? categoriesFallbackOrder : categoriesOrder
  return order.indexOf(category1.name.toLowerCase()) - order.indexOf(category2.name.toLowerCase())
}

export const convertCategoryNameToLabel = (
  categoryName: string,
  optionName: string,
  t: TFunction,
) => {
  if (categoryName === 'release') {
    return optionName
  } else if (optionName.includes('|')) {
    return optionName
      .split('|')
      .map(name => getAfaFilterTranslation(t, `Afa.fO.recommendedUse.${name}`, name))
      .join(', ')
  } else {
    return getAfaFilterTranslation(t, `Afa.fO.${categoryName}.${optionName}`, optionName)
  }
}

export const sortAfaFacets = (a: AfaFacet, b: AfaFacet) => {
  const orderA = a.order ?? Number.MAX_SAFE_INTEGER
  const orderB = b.order ?? Number.MAX_SAFE_INTEGER

  if (isNumber(orderA) && isNumber(orderB)) {
    return orderA - orderB
  } else {
    // eslint-disable-next-line no-console
    console.log('Order field in facet is not a number')
    return 0
  }
}

export const sortAfaSizes = (s1: string, s2: string) => {
  const size1 = s1 || ''
  const size2 = s2 || ''

  const conventionalSizeMap: Record<string, number> = {
    XXXS: 1,
    'XXXS/XXS': 2,
    XXS: 3,
    'XXS/XS': 4,
    XS: 5,
    'XS/S': 6,
    S: 7,
    'S/M': 8,
    M: 9,
    'M/L': 10,
    L: 11,
    'L/XL': 12,
    XL: 13,
    'XL/XXL': 14,
    XXL: 15,
    'XXL/XXXL': 16,
    XXXL: 17,
    U: 18,
  }

  const conventionalSizes = Object.keys(conventionalSizeMap)
  const isConventionalSize = conventionalSizes.includes(size1) && conventionalSizes.includes(size2)
  if (isConventionalSize) {
    return conventionalSizeMap[size1] - conventionalSizeMap[size2]
  }

  const decimalSeparator = isNaN(Number('1.1')) ? ',' : '.'
  const wrongDecimalSeparator = decimalSeparator === '.' ? ',' : '.'
  const s1Num = Number(size1.replace(wrongDecimalSeparator, decimalSeparator))
  const s2Num = Number(size2.replace(wrongDecimalSeparator, decimalSeparator))
  const isNumberSize = !isNaN(s1Num) && !isNaN(s2Num)
  if (isNumberSize) {
    return s1Num - s2Num
  }

  // we can treat all sizes that have 2 numbers separated by a char as if they were decimal numbers:
  // 42x32 -> 42,32
  // 11-12 -> 11,12
  // 10/11 -> 10,11
  const separatedNumbersRegExp = new RegExp('^([0-9.,]+)[Xx/-]([0-9.,]+)$')
  const separatedNumbersMatch1 = size1.match(separatedNumbersRegExp)
  const separatedNumber1 = separatedNumbersMatch1
    ? Number(
        `${separatedNumbersMatch1[1]}${decimalSeparator}${
          Number(separatedNumbersMatch1[2]) > 9 ? '' : 0
        }${separatedNumbersMatch1[2]}`,
      )
    : 0
  const separatedNumbersMatch2 = size2.match(separatedNumbersRegExp)
  const separatedNumber2 = separatedNumbersMatch2
    ? Number(
        `${separatedNumbersMatch2[1]}${decimalSeparator}${
          Number(separatedNumbersMatch2[2]) > 9 ? '' : 0
        }${separatedNumbersMatch2[2]}`,
      )
    : 0
  if (separatedNumber1 !== 0 && separatedNumber2 !== 0) {
    return separatedNumber1 - separatedNumber2
  }

  // sort sizes like 17WDE, 195CM, 16REG, ecc.
  const numbersFollewedByChars = new RegExp(/^([0-9.,]+)\w+$/)
  const s1NumbersFollewdByChars = size1.match(numbersFollewedByChars)
  const s1OnlyNumbers = s1NumbersFollewdByChars
    ? Number(s1NumbersFollewdByChars[1].replace(wrongDecimalSeparator, decimalSeparator))
    : 0
  const s2NumbersFollewdByChars = size2.match(numbersFollewedByChars)

  const s2OnlyNumbers = s2NumbersFollewdByChars
    ? Number(s2NumbersFollewdByChars[1].replace(wrongDecimalSeparator, decimalSeparator))
    : 0
  const thereAreNumbers = s1OnlyNumbers !== 0 && s2OnlyNumbers !== 0
  if (thereAreNumbers) {
    return s1OnlyNumbers - s2OnlyNumbers
  }

  return size1.localeCompare(size2)
}

export const sortAfaFiltersOptions = (a: Option, b: Option, selectedCategory: AfaFacet): number => {
  const categoriesSortedInDESC = ['season']
  const isSelectedCategorySortedInDESC = categoriesSortedInDESC.includes(
    selectedCategory.name.toLowerCase(),
  )

  const nameA = a.name
  const nameB = b.name
  const orderA = a.order
  const orderB = b.order

  const orderAIsValid = orderA !== undefined && isNumber(orderA)
  const orderBIsValid = orderB !== undefined && isNumber(orderB)

  if (orderAIsValid && orderBIsValid) {
    return orderA - orderB
  }

  if (orderAIsValid && !orderBIsValid) {
    return -1
  } else if (!orderAIsValid && orderBIsValid) {
    return 1
  }

  if (selectedCategory.name.toLowerCase() === 'size') {
    return sortAfaSizes(nameA, nameB)
  }

  return isSelectedCategorySortedInDESC ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB)
}

export const isSeasonSale = (drop: AfaDrop): drop is AfaSeasonSale => drop.type === 'SEASON'

export const isPrebook = (drop: AfaDrop): drop is AfaPrebook => drop.type === 'DROP'

export const getCartRowAvailableQuantity = (
  {
    upc,
    availabilityDate,
    dropType,
  }: { upc: string; availabilityDate: DdMmYyyyDateString; dropType: DropType },
  availability?: Record<Upc, AfaDrop[]>,
) => {
  if (!availability) {
    return undefined
  }

  const cartRowSeasonSale = (availability[upc] || []).find(
    drop => drop.availabilityDate === availabilityDate && drop.type === dropType,
  )

  return cartRowSeasonSale?.availableQuantity
}

export const addCalculatedAvailability = (products: AfaCartProduct[], availability?: number) => {
  return products.reduce(
    (products, { availabilityDate, upc, deliveryDate, unconfirmedQuantity, key }, index) => {
      products.push({
        upc,
        deliveryDate,
        unconfirmedQuantity,
        availabilityDate,
        key,
        calculatedAvailability:
          index >= 1 && availability && products[index - 1].calculatedAvailability
            ? Number(products[index - 1].calculatedAvailability) -
              products[index - 1].unconfirmedQuantity
            : availability,
      })

      return products
    },
    [] as {
      upc: string
      deliveryDate: string
      availabilityDate: DdMmYyyyDateString
      calculatedAvailability?: number
      unconfirmedQuantity: number
      key: string
    }[],
  )
}

export const getUpcAvailabilityByDelivery = ({
  availabilityDate,
  deliveryDate,
  cartProducts,
  availabilityByUpc,
  upc,
  groupDoors,
}: {
  upc: string
  cartProducts: AfaCartProduct[]
  availabilityDate: string
  deliveryDate: string
  availabilityByUpc: { upc: string; availableQuantity?: number }[]
  groupDoors?: boolean
}) => {
  const sizeFromAllDevileries = cartProducts
    .filter(
      cartProduct => cartProduct.upc === upc && cartProduct.availabilityDate === availabilityDate,
    )
    .sort(sortProductsByDelivery)

  const availabilitySize = availabilityByUpc.find(availability => availability.upc === upc)
    ?.availableQuantity

  if (availabilitySize === undefined) {
    return undefined
  }

  const upcsFromAllDevileriesWithAvailability = addCalculatedAvailability(
    sizeFromAllDevileries,
    availabilitySize,
  )

  const upcAvailabilityByDelivery = upcsFromAllDevileriesWithAvailability
    .filter(cartProduct => cartProduct.upc === upc && cartProduct.deliveryDate === deliveryDate)
    .sort((a, b) => {
      if (!a.calculatedAvailability || !b.calculatedAvailability) return 0
      if (groupDoors) {
        return a.calculatedAvailability - b.calculatedAvailability
      } else {
        return b.calculatedAvailability - a.calculatedAvailability
      }
    })[0]?.calculatedAvailability

  return upcAvailabilityByDelivery
}

export const getUnavailableKeys = (
  cartProducts: AfaCartProduct[],
  availability?: Record<string, AfaDrop[]>,
) => {
  if (!availability) {
    return []
  }

  const groupedUpcs = cartProducts.reduce((result, cartProduct) => {
    const key = `${cartProduct.upc}___${cartProduct.availabilityDate}___${cartProduct.dropType}`
    result[key] = result[key] ? result[key].concat(cartProduct) : [cartProduct]
    return result
  }, {} as Record<string, AfaCartProduct[]>)

  const unavailableKeys = Object.values(groupedUpcs).flatMap(groupedCartProducts => {
    const availableQuantity = getCartRowAvailableQuantity(groupedCartProducts[0], availability)

    const groupedCartProductsWithCalculatedAvailability = addCalculatedAvailability(
      groupedCartProducts,
      availableQuantity,
    )

    return groupedCartProductsWithCalculatedAvailability
      .filter(product => {
        const {
          availabilityDate,
          upc,
          deliveryDate,
          unconfirmedQuantity,
          calculatedAvailability,
        } = product

        const availability = getUpcAvailabilityByDelivery({
          cartProducts,
          upc,
          availabilityDate,
          availabilityByUpc: [{ upc, availableQuantity }],
          deliveryDate,
          groupDoors: true,
        })

        return (
          availableQuantity !== undefined &&
          availability !== undefined &&
          calculatedAvailability !== undefined &&
          unconfirmedQuantity > calculatedAvailability
        )
      })
      .map(({ key }) => key)
  })

  return unavailableKeys
}

export const getAppliedFilter = (
  cartFilter: AfaCartFilter,
  cartFilterValue: string[] | null,
  cartProductDetails: AfaProduct,
  cartProduct: AfaCartProduct,
) => {
  const model = cartProductDetails
  const moco = cartProductDetails.mocos[cartProduct.colorCode]
  const upc = cartProductDetails.mocos[cartProduct.colorCode]?.sizes[cartProduct.upc]
  const mocoAvds = moco?.availabilityDataList.map(avd => avd.avdCode)

  switch (cartFilter) {
    case 'size':
      return !!upc?.size && cartFilterValue?.includes(upc?.size)
    case 'sport':
      return cartFilterValue?.some(sport => moco?.sports.includes(sport))
    case 'colorFacet':
      return !!moco?.colorDescription && cartFilterValue?.includes(moco?.colorDescription)
    case 'technology':
      return cartFilterValue?.some(technology => moco?.technologies.includes(technology))
    case 'gender':
      return !!moco?.gender && cartFilterValue?.includes(moco?.gender)
    case 'release':
      return cartFilterValue?.some(season => upc?.season.includes(season))
    case 'afacatalogid':
      return cartFilterValue?.some(afacatalogid => upc?.afacatalogid.includes(afacatalogid))
    case 'family':
      return !!model.family && cartFilterValue?.includes(model.family)
    case 'productType':
      return !!model.productType && cartFilterValue?.includes(model.productType)
    case 'collection':
      return !!model.collection && cartFilterValue?.includes(model.collection)
    case 'limitedRelease':
      return !!upc?.limitedRelease && cartFilterValue?.includes(upc?.limitedRelease)
    case 'fabric':
      return !!upc?.fabric && cartFilterValue?.includes(upc?.fabric)
    case 'fabricGroup':
      return !!model.fabricGroup && cartFilterValue?.includes(model.fabricGroup)
    case 'fit':
      return !!upc?.fit && cartFilterValue?.includes(upc?.fit)
    case 'afaStyle':
      return !!model?.afaStyle && cartFilterValue?.includes(model?.afaStyle)
    case 'category':
      return !!model?.category && cartFilterValue?.includes(model?.category)
    case 'adv':
      return !!upc?.adv
    case 'avds':
      return (
        !!moco?.availabilityDataList.length && cartFilterValue?.some(avd => mocoAvds.includes(avd))
      )
    case 'hasKeylooks':
      return !!moco?.keylooks && moco.keylooks.length > 0
  }
}

export const applyAfaFilters = (
  cartFilters: Record<AfaCartFilter, string[] | null>,
  cartProductDetails: AfaProduct,
  cartProduct: AfaCartProduct,
) => {
  return Object.entries(cartFilters)
    .map(([cartFilter, cartFilterValue]) => {
      return {
        selectedOptions: cartFilterValue,
        check: getAppliedFilter(
          cartFilter as AfaCartFilter,
          cartFilterValue,
          cartProductDetails,
          cartProduct,
        ),
      }
    })
    .every(({ selectedOptions, check }) => (!selectedOptions ? true : check))
}

export const getNextAvailability = (
  afaCartProduct: AfaCartProduct[],
  data?: Record<string, AfaDrop[]> | undefined,
): string | undefined => {
  const mocoDeliveryDates = afaCartProduct
    .flatMap(product => {
      return data && data[product.upc]
    })
    .filter(isNotUndefined)
    .map(({ minDeliveryDate }) => minDeliveryDate)

  const mocoDeliveryDates_deduplicated = [...new Set(mocoDeliveryDates)]

  const mocoDeliveryDates_sorted = mocoDeliveryDates_deduplicated.sort(sortDdMmYyyyDates)

  const dropIndex = mocoDeliveryDates_sorted.indexOf(afaCartProduct[0]?.minDeliveryDate)

  return mocoDeliveryDates_sorted[dropIndex + 1]
}

export const sortDropsByMinDeliveryDate = (
  { minDeliveryDate: minDeliveryDate1 }: { minDeliveryDate: string },
  { minDeliveryDate: minDeliveryDate2 }: { minDeliveryDate: string },
) => {
  const firstDate = (minDeliveryDate1 && convertDdMmYyyyToDate(minDeliveryDate1).getTime()) || 0
  const secondDate = (minDeliveryDate2 && convertDdMmYyyyToDate(minDeliveryDate2).getTime()) || 0
  if (firstDate > secondDate) {
    return 1
  } else if (firstDate < secondDate) {
    return -1
  } else {
    return 0
  }
}

export const getAllUnavailableCartProductsFromKeys = (
  cartProducts: AfaCartProduct[],
  unavailableKeys: string[],
  useMocoGrouping?: boolean,
) => {
  const unavailableCartProductsKeys = cartProducts
    .filter(({ key }) => unavailableKeys.includes(key))
    .map(({ availabilityDate, upc, dropType, modelCode, colorCode }) => {
      const productKey = useMocoGrouping ? `${modelCode}-${colorCode}` : upc
      return `${availabilityDate}-${dropType}-${productKey}`
    })
  const unavailableCartProduct = cartProducts.filter(
    ({ availabilityDate, upc, dropType, modelCode, colorCode }) => {
      const productKey = useMocoGrouping ? `${modelCode}-${colorCode}` : upc
      return unavailableCartProductsKeys.includes(`${availabilityDate}-${dropType}-${productKey}`)
    },
  )
  return unavailableCartProduct
}

export const isCartProductFromDrop = (cartProduct: AfaCartProduct, drop: AfaDrop) => {
  return (
    cartProduct.upc === drop.upc &&
    cartProduct.availabilityDate === drop.availabilityDate &&
    cartProduct.minDeliveryDate === drop.minDeliveryDate &&
    cartProduct.dropType === drop.type
  )
}

export const getAfaCartQuantity = (cartProducts: AfaCartProduct[]) => {
  const quantity = cartProducts.reduce((result, item) => {
    result += item.unconfirmedQuantity
    return result
  }, 0)
  return quantity
}

export const isAfaCartProductMatching = (
  cartProduct1: AfaCartProduct | UpdateAfaCartProductPayload,
  cartProduct2: AfaCartProduct | UpdateAfaCartProductPayload,
) => {
  const isMatching =
    cartProduct1.key === cartProduct2.key ||
    (cartProduct1.upc === cartProduct2.upc &&
      cartProduct1.doorId === cartProduct2.doorId &&
      cartProduct1.dropType === cartProduct2.dropType &&
      cartProduct1.availabilityDate === cartProduct2.availabilityDate &&
      cartProduct1.deliveryDate === cartProduct2.deliveryDate &&
      cartProduct1.afaCatalogId === cartProduct2.afaCatalogId)
  return isMatching
}
