import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import OpenOthersIcon from '../../components/icons/OpenOthersIcon'
import QRCode from '../../components/VirtualMirrorModal/QRCode'
import { CollectionHighlightProduct } from '../../model/brand'
import { HTMLVideoElementWithDisablePip } from '../../model/model'
import { Moco, Product } from '../../model/product'
import {
  getSeeThroughWithoutContent,
  setSeethroughModalVisibility,
  toggleWishlistAction,
} from '../../store/actions'
import { brandsSelector } from '../../store/brands/selectors'
import { pdpHighlightSeeThemOnAvailableForMocosSelector } from '../../store/pdp/selectors'
import { wishlistSelector } from '../../store/wishlist/selectors'
import {
  CtaButton,
  GlassBox,
  maxHeight,
  OnDisplayTitle,
  XSHeight,
} from '../../style/DigitalEventsCommonComponents'
import { breakpointsCross, getFluidFontSize, pxToRem, spacing } from '../../style/theme'
import Icon from '../../styleguide/Icon'
import Characteristics from './Characteristics'
import { BrandAndModel, MocoImage } from './MocoCard'
import MoreFeatures from './MoreFeatures'
import OtherColors from './OtherColors'
import ZoomModal from './ZoomModal'
import { cartProductsRawSelector } from '../../store/cart/selectors'

const CurrentWrapper = styled.div`
  padding-left: ${pxToRem(spacing(8))}rem;
  padding-right: ${pxToRem(spacing(8))}rem;
  width: 50%;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  button {
    cursor: pointer;
  }

  @media (max-height: ${maxHeight}) {
    padding-left: ${pxToRem(spacing(2))}rem;
    padding-right: ${pxToRem(spacing(6))}rem;
    padding-top: 0;
  }

  @media (max-height: ${XSHeight}) {
    padding-top: 0;
    padding-left: ${pxToRem(spacing(2))}rem;
    padding-right: ${pxToRem(spacing(2))}rem;
  }
`

const StyledCtaButton = styled(CtaButton)`
  display: flex;
  align-items: center;

  @media (max-width: ${breakpointsCross.L.max}) {
    padding: ${pxToRem(spacing(1))}rem ${pxToRem(spacing(2))}rem;
    border-radius: 0.35rem;
    font-size: ${getFluidFontSize('14px')};
  }
`

const QRSizesCSS = css`
  margin-top: -50px;

  @media (max-width: ${breakpointsCross.XL.max}) {
    margin-top: -10px;
    width: 140px;
    height: 140px;
  }

  @media (max-width: ${breakpointsCross.L.max}) {
    margin-top: 10px;
    width: 90px;
    height: 90px;
  }

  @media (max-height: ${maxHeight}) {
    margin-top: 10px;
    width: 70px;
    height: 70px;
  }

  @media (max-height: ${XSHeight}) {
    margin-top: 0;
    width: 55px;
    height: 55px;
  }
`

const StyledQRCode = styled(QRCode)`
  svg {
    padding: 0;
  }

  ${QRSizesCSS}
`

const QRCodePlaceholder = styled.div`
  height: 200px;
  width: 200px;
  ${QRSizesCSS}
`

const StyledBrandAndModel = styled(BrandAndModel)`
  min-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > span {
    font-size: ${getFluidFontSize('26px')};
    margin-right: 2rem;
  }

  p {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-height: ${XSHeight}) {
    justify-content: flex-start;
  }
`

const DisplayContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledGlassBox = styled(GlassBox)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const IconsRow = styled.div`
  position: absolute;
  top: ${pxToRem(spacing(2))}rem;
  right: ${pxToRem(spacing(2))}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${getFluidFontSize('25px')};

  img {
    cursor: pointer;
  }
`

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
`

const ProductImage = styled(MocoImage)`
  height: auto;
  width: auto;
  align-self: center;
  display: block;
`

const ImageAndTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1;
  align-items: flex-start;

  img {
    margin: 0 auto;
    height: auto;
    width: 50%;
    padding-right: 0.5rem;
  }

  p {
    padding: ${pxToRem(spacing(0.5))}rem 0;

    @media (max-height: ${XSHeight}) {
      display: none;
    }

    @media (min-width: ${breakpointsCross.M.min}) {
      padding: ${pxToRem(spacing(2))}rem 0;
    }

    @media (max-height: ${maxHeight}) {
      padding: ${pxToRem(spacing(1))}rem 0;
      margin-bottom: 0;
    }
  }
`

const ColorsAndQR = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0;

  & > div {
    width: 50%;
  }
`

const DisplayWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const OtherColorsLabel = styled.span``

const OtherData = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0;

  & > div,
  & > span {
    width: 50%;
    flex: 1;
  }

  & > span {
    text-align: center;
    opacity: 60%;
  }
`

const MoreFeaturesButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: ${getFluidFontSize('14px')};
  margin-bottom: 0;
  font-weight: bold;

  svg {
    margin-right: ${spacing(1)}px;
  }

  @media (max-height: ${maxHeight}) {
    margin-bottom: -${spacing(2)}px;
  }

  @media (max-height: ${XSHeight}) {
    margin-bottom: 0;
    font-size: ${getFluidFontSize('16px')};
  }
`

type Props = {
  loadedProducts: Product[]
  currentUpc: string | undefined
  currentMoco: Moco | undefined
  getMocoByModelCode: (modelCode?: string | undefined) => Moco | undefined
  currentProductModelCode: string | undefined
  videoRef: React.RefObject<HTMLVideoElementWithDisablePip>
  getModelByModelCode: (modelCode: any) => Product | undefined
  currentModel: Product | undefined
  currentProduct: CollectionHighlightProduct | null
  setCurrentProductVariant: React.Dispatch<React.SetStateAction<string | null>>
  hasVto: boolean | '' | undefined
  vtoUrl: string
}

const HightlightBox: React.FC<Props> = ({
  loadedProducts,
  currentUpc,
  currentMoco,
  getMocoByModelCode,
  currentProductModelCode,
  videoRef,
  getModelByModelCode,
  currentModel,
  currentProduct,
  setCurrentProductVariant,
  hasVto,
  vtoUrl,
}) => {
  const { t } = useTranslation()

  const [isModalVisible, setModalVisible] = useState(false)
  const [openModalGlass, setOpenModalGlass] = useState(false)

  const wishlist = useSelector(wishlistSelector)
  const cartProducts = useSelector(cartProductsRawSelector)
  const brands = useSelector(brandsSelector)
  const seeThemOnAvailableForMocos = useSelector(pdpHighlightSeeThemOnAvailableForMocosSelector)

  const dispatch = useDispatch()

  const showModalProductView = (moco?: Moco) => {
    if (!moco) {
      return
    }

    dispatch(setSeethroughModalVisibility(true))
    const { lensColor, mocoCode, brandCode } = moco
    dispatch(getSeeThroughWithoutContent(mocoCode, brandCode, (lensColor || {}).id))
  }

  const isInWishlist = (item?: { mocoCode: string }) => {
    if (item && wishlist && wishlist.products) {
      return !!wishlist.products.find(({ mocoCode }) => mocoCode === item.mocoCode)
    }
  }

  const OnToggleWishlist = (product?: Moco, brandCode?: string) => {
    if (!product || !brandCode) {
      return
    }

    dispatch(
      toggleWishlistAction(
        Object.values(product.sizes)
          .filter(({ upc }) => {
            return !cartProducts.find(cartProduct => cartProduct.upc === upc)
          })
          .map(({ upc, mocoCode, size, skuCode, categoryId }) => ({
            brandCode,
            upc,
            mocoCode,
            size,
            skuCode,
            categoryId,
            toDelete: isInWishlist(currentMoco),
          })),
      ),
    )
  }

  return (
    <CurrentWrapper>
      <OnDisplayTitle>{t('Highlight.detail_title')}</OnDisplayTitle>
      <DisplayWrapper>
        {loadedProducts.length &&
        currentUpc &&
        currentMoco &&
        getMocoByModelCode(currentProductModelCode) ? (
          <StyledGlassBox>
            <DisplayContent>
              <ImageAndTitle>
                <ProductImage
                  angle="quarter"
                  moco={currentMoco}
                  key={currentMoco.mocoCode}
                  onClick={() => {
                    setOpenModalGlass(true)
                    videoRef.current?.pause()
                  }}
                />
                <StyledBrandAndModel
                  moco={currentMoco}
                  styleName={
                    getModelByModelCode(currentProductModelCode?.replaceAll('_', ' '))?.styleName
                      ?.label
                  }
                >
                  <Characteristics moco={currentMoco} model={currentModel} />
                </StyledBrandAndModel>
                <ZoomModal
                  onClose={() => setOpenModalGlass(false)}
                  visible={openModalGlass}
                  moco={currentMoco}
                  model={currentModel}
                  brands={brands}
                />
              </ImageAndTitle>
              <OtherData>
                <OtherColorsLabel>
                  {Object.values(getModelByModelCode(currentProductModelCode)?.mocos || {}).length}{' '}
                  {t('Highlight.other_colors')}
                </OtherColorsLabel>
                <MoreFeaturesButton
                  onClick={() => {
                    videoRef.current?.pause()
                    setModalVisible(true)
                  }}
                >
                  <OpenOthersIcon />
                  {t('Highlight.more_features')}
                </MoreFeaturesButton>
                <MoreFeatures
                  onClose={() => setModalVisible(false)}
                  visible={isModalVisible}
                  moco={currentMoco}
                  model={currentModel}
                />
              </OtherData>
              <ColorsAndQR>
                <OtherColors
                  key={currentProduct?.id}
                  selectedItem={currentMoco?.mocoCode || ''}
                  mocos={Object.values(getModelByModelCode(currentProductModelCode)?.mocos || {})}
                  onProductSelect={product => {
                    setCurrentProductVariant(product.mocoCode.replaceAll('__', ' '))
                  }}
                />
                <ButtonsWrapper>
                  {seeThemOnAvailableForMocos[
                    getMocoByModelCode(currentProductModelCode)?.mocoCode || ''
                  ] ? (
                    <StyledCtaButton
                      onClick={() => {
                        videoRef.current?.pause()
                        showModalProductView(currentMoco)
                      }}
                    >
                      {t('ProductPage.seeThemOn')}
                    </StyledCtaButton>
                  ) : (
                    <div />
                  )}
                  {hasVto && vtoUrl ? (
                    <StyledQRCode url={vtoUrl} size={200} className="" />
                  ) : (
                    <QRCodePlaceholder /> // placeholder to avoid layout flicker
                  )}
                </ButtonsWrapper>
              </ColorsAndQR>
            </DisplayContent>
            <IconsRow>
              <Icon
                type="star"
                className={`star-icon ${isInWishlist(currentMoco) ? 'star-icon--active' : ''}`}
                theme={isInWishlist(currentMoco) ? 'filled' : 'outlined'}
                onClick={() =>
                  OnToggleWishlist(
                    currentMoco,
                    getModelByModelCode(currentProductModelCode || '')?.brandCode,
                  )
                }
              />
            </IconsRow>
          </StyledGlassBox>
        ) : null}
      </DisplayWrapper>
    </CurrentWrapper>
  )
}

export default HightlightBox
