import DownloadDocument from './DownloadDocument'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSelectedSwitch, getSelectedWhiteboard } from '../../../store/whiteboard/selectors'
import { useGetSingleWhiteboardQuery } from '../../../services/whiteboard'
import useCheckIfImageNeedsProxy from '../../../hooks/useCheckIfImageNeedsProxy'
import noBackground from '../../../components/icons/noBackgroundWithBorder.svg'
import { useParams } from 'react-router-dom'
import { WhiteboardType } from '../../../model/whiteboard'
import { PDFViewer } from '@react-pdf/renderer'
import { environmentSelector } from '../../../store/app/selectors'
import { customerNationalitySelector } from '../../../store/customer/selectors'
import { useGetBrandsQuery } from '../../../services/afa'

const DownloadPreview = () => {
  const selectedType = useSelector(getSelectedSwitch)
  const selectedWhiteboard = useSelector(getSelectedWhiteboard)
  const environment = useSelector(environmentSelector)
  const [images, setImages] = useState<Record<string, string>>({})
  const { fetchImage } = useCheckIfImageNeedsProxy()
  const { id } = useParams<{ id: string }>()
  const customerNationality = useSelector(customerNationalitySelector)
  const brandsQuery = useGetBrandsQuery()

  const defaultCurrency = brandsQuery.data?.[0].currency || ''

  const whiteboardQuery = useGetSingleWhiteboardQuery(selectedType, id)
  const whiteboard = selectedWhiteboard || whiteboardQuery.data

  const imagesToFetch = useMemo(() => {
    return whiteboard?.boards.flatMap(({ items }) => {
      return items.map(({ id, product }) => {
        return {
          id,
          url: product.image,
        }
      })
    })
  }, [whiteboard?.boards])

  const getImage = (image: string) =>
    image?.indexOf('cdn-record-files-pi/') > -1 ? image.split('cdn-record-files-pi/')[1] : image

  useEffect(() => {
    if (imagesToFetch) {
      imagesToFetch.forEach(item => {
        const { id, url } = item
        fetchImage(getImage(url), environment)
          .then(localUrl => setImages(images => ({ ...images, [id]: localUrl })))
          .catch(() => setImages(images => ({ ...images, [id]: noBackground })))
      })
    }
  }, [fetchImage, imagesToFetch, environment])

  return (
    <PDFViewer style={{ height: '100vh' }}>
      <DownloadDocument
        whiteboard={whiteboard as WhiteboardType}
        images={images}
        customerNationality={customerNationality}
        currency={defaultCurrency}
      />
    </PDFViewer>
  )
}

export default DownloadPreview
