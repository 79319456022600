import afaCartNotificationsSliceActions from '../store/afaCartNotifications/actions'
import afaCartOutOfAssortmentActions from '../store/afaCartOutOfAssortment/actions'
import afaCartAdjustActions from '../store/afaCartAdjust/actions'
import { AfaCartAdjustType } from '../store/afaCartAdjust/slice'
import { getAllUnavailableCartProductsFromKeys, getUnavailableKeys } from '../libs/afa'
import { useLazyGetCartAvailabilityRealtimeQuery } from '../services/afaProduct'
import { useDispatch, useSelector } from 'react-redux'
import {
  afaCartNotificationsSelectors,
  currentNotificationsListSelector,
} from '../store/afaCartNotifications/selectors'
import { useGetExpiredCartProducts } from './useGetExpiredCartProducts'
import { AfaCartProduct } from '../model/afa'
import useAfaGetOutOfAssortmentCartProducts from './useAfaGetOutOfAssortmentCartProducts'
import { DebuggerFeatures } from '../Debug'
import { appSelectors } from '../store/app/selectors'
import afaCartActions from '../store/afaCart/actions'
import { useCallback } from 'react'

export const useAfaCheckCartNotifications = () => {
  const dispatch = useDispatch()
  const adjustBypass = useSelector(
    appSelectors.isActiveDebugger(DebuggerFeatures.AFA_ADJUST_CHECKOUT_BYPASS),
  )
  const showNotifications = useSelector(afaCartNotificationsSelectors.showNotifications)
  const currentNotificationsList = useSelector(currentNotificationsListSelector)
  const { expiredDropsCartProducts, expiredSeasonsCartProducts } = useGetExpiredCartProducts()
  const outOfAssortmentCartProducts = useAfaGetOutOfAssortmentCartProducts()
  const expiredSeasonsCartProductsUpcs = expiredSeasonsCartProducts.map(({ key }) => key)

  const [
    getAvailabilityRealtime,
    { isLoading: isLoadingAvailabilityRealtime, isFetching: isFetchingAvailabilityRealtime },
  ] = useLazyGetCartAvailabilityRealtimeQuery()

  const checkCartNotifications = async (cartProducts: AfaCartProduct[]) => {
    if (isLoadingAvailabilityRealtime || isFetchingAvailabilityRealtime) {
      return false
    }

    const availabilityRealtime = await getAvailabilityRealtime()
    const unavailableKeysRealtime = getUnavailableKeys(
      cartProducts,
      availabilityRealtime.data,
    ).concat(expiredSeasonsCartProductsUpcs)
    const isOutOfStock = availabilityRealtime?.data && unavailableKeysRealtime.length > 0
    dispatch(afaCartActions.setDropsRealtime(availabilityRealtime?.data || null))

    if (!showNotifications && (currentNotificationsList.length > 0 || isOutOfStock)) {
      dispatch(afaCartNotificationsSliceActions.setShowNotifications(true))
      return false
    }

    if (outOfAssortmentCartProducts.length > 0 && !adjustBypass) {
      dispatch(afaCartOutOfAssortmentActions.setIsOpen(true))
      return false
    }

    if (expiredDropsCartProducts.length > 0 && !adjustBypass) {
      dispatch(afaCartAdjustActions.setCartProducts(expiredDropsCartProducts))
      dispatch(afaCartAdjustActions.setIsOpen(AfaCartAdjustType.EXPIRED_DROPS))
      return false
    }

    if (unavailableKeysRealtime.length > 0 && !adjustBypass) {
      dispatch(
        afaCartAdjustActions.setCartProducts(
          getAllUnavailableCartProductsFromKeys(cartProducts, unavailableKeysRealtime, true),
        ),
      )
      dispatch(afaCartAdjustActions.setIsOpen(AfaCartAdjustType.OUT_OF_STOCK))
      return false
    }

    return true
  }

  const resetCartNotifications = useCallback(() => {
    dispatch(afaCartNotificationsSliceActions.setShowNotifications(false))
    dispatch(afaCartAdjustActions.setIsOpen(false))
    dispatch(afaCartOutOfAssortmentActions.setIsOpen(false))
    dispatch(afaCartActions.setDropsRealtime(null))
    dispatch(afaCartAdjustActions.setCartProducts([]))
  }, [dispatch])

  const isChecking = isLoadingAvailabilityRealtime || isFetchingAvailabilityRealtime

  return { checkCartNotifications, isChecking, resetCartNotifications }
}
