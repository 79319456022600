import {
  CartProductsListRow,
  CustomInput,
  LabelInput,
} from '../components/AfaCheckoutCommonComponents'
import React, { useCallback, useEffect, useState } from 'react'
import {
  breakpoints,
  duration,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../../style/theme'

import { AfaCartProduct } from '../../../model/afa'
import BoldCalendarIcon from '../../../components/icons/BoldCalendarIcon'
import TrashIcon from '../../../components/icons/TrashIcon'
import WarningIcon from '../../../components/icons/WarningIcon'
import { customerDoorsSelector } from '../../../store/customer/selectors'
import { debounce } from 'lodash'
import { format } from 'date-fns'
import styled from 'styled-components'
import { afaCartProductDetailsSelector } from '../../../store/afaCart/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useUpdateAfaCartProductsMutation } from '../../../services/afaCart'
import { convertDdMmYyyyToDate } from '../../../libs/time'
import afaCartActions from '../../../store/afaCart/actions'
import { getDeliveryReference } from '../../../store/afaCart/selectors'
import { getProductImageUrl } from '../../../libs/productImages'

const Wrapper = styled(CartProductsListRow)<{ disabled?: boolean }>`
  border-bottom: ${pxToRem(spacing(0.2))}rem solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: ${palette.platinum};
  background-color: ${palette.white};

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`

const ProcessTableCell = styled.div`
  color: ${palette.tangaroa};
  font-weight: bold;
  font-size: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};

  .anticon {
    height: 1.5em;
    width: 1.5em;
  }
`

const DoorProcessTableCell = styled(ProcessTableCell)`
  font-size: ${getFluidSizeWithFullFormula('px', 14, 24, 1366, 3840)};
  letter-spacing: 0.7px;

  @media (min-width: ${breakpoints.L}) {
    letter-spacing: 1.2px;
  }
`

const DeliveryDateProcessTableCell = styled(ProcessTableCell)`
  font-size: ${getFluidSizeWithFullFormula('px', 14, 24, 1366, 3840)};
  font-weight: bold;

  @media (min-width: ${breakpoints.L}) {
    letter-spacing: 0.3px;
  }
`

const ImageIconsAndCode = styled(ProcessTableCell)`
  font-weight: bold;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${getFluidSizeWithFullFormula('px', 12, 34, 1366, 3840)};
  padding: 0.5rem;

  @media (min-width: ${breakpoints.L}) {
    flex-direction: row;
  }
`

const ImageIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledWarningIcons = styled(WarningIcon)`
  width: 1.5em;
  height: 1.5em;
  color: ${palette.pomegranate};
`

const ImageWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  width: ${getFluidSizeWithFullFormula('px', 90, 127, 1366, 3840)};
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Code = styled.div`
  flex-wrap: nowrap;
  white-space: nowrap;
  font-weight: bold;
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
  color: ${palette.cloudBurst};
`

const ItemDoorName = styled.span`
  display: inline-block;
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
`

const InputWrapperBodyReference = styled.div`
  border: solid 1px ${palette.shadowBlue};
  border-radius: ${pxToRem(spacing(0.5))}rem;
  background: ${palette.white};
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: ${pxToRem(8)}rem ${pxToRem(12)}rem;
  font-size: clamp(10px, ${getFluidSizeWithFullFormula('px', 10, 24, 1366, 3840)}, 24px);
  letter-spacing: 0.3px;
  font-weight: bold;

  label {
    color: ${palette.shadowBlue};
  }
`

const Tooltip = styled.div`
  position: relative;
  display: inline-block;

  .tooltip-text {
    opacity: 0;
    transition: opacity ${duration.standard}ms;
    width: max-content;
    background-color: ${palette.pomegranate};
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    top: -5px;
    left: 130%;
    z-index: 1;
  }

  &:hover .tooltip-text {
    opacity: 1;
  }

  .tooltip-text::after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent ${palette.pomegranate} transparent transparent;
  }
`

const DeliveryDateProcessTableCellContent = styled.div`
  display: flex;
  justify-content: center;
  gap: ${getFluidSizeWithFullFormula('px', 16, 6, 1366, 3840)};

  svg {
    width: ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)};
  }
`

const TrashIconWrapper = styled.div`
  width: clamp(16.3px, ${getFluidSizeWithFullFormula('px', 16.3, 32.6, 1366, 3840)}, 32.6px);
  height: clamp(16.8px, ${getFluidSizeWithFullFormula('px', 16.8, 33.7, 1366, 3840)}, 33.7px);

  svg {
    width: clamp(16.3px, ${getFluidSizeWithFullFormula('px', 16.3, 32.6, 1366, 3840)}, 32.6px);
    height: clamp(16.8px, ${getFluidSizeWithFullFormula('px', 16.8, 33.7, 1366, 3840)}, 33.7px);
  }
`

const TrashButton = styled.button`
  background-color: ${palette.platinum};
  border-radius: 50%;
  padding: clamp(7.6px, ${getFluidSizeWithFullFormula('px', 7.6, 15.2, 1366, 3840)}, 15.2px);
`

const ProcessTableRow: React.FC<{
  checkoutProduct: AfaCartProduct
  style: Record<string, any>
  updateAfaCartProduct: ReturnType<typeof useUpdateAfaCartProductsMutation>[0]
}> = ({ checkoutProduct, style, updateAfaCartProduct }) => {
  const {
    doorId,
    unconfirmedQuantity,
    modelCode,
    colorCode,
    deliveryDate,
    reference,
    outOfAssortment,
    status,
    upc,
    key,
  } = checkoutProduct

  const product = useSelector(afaCartProductDetailsSelector(modelCode))

  const moco = product?.mocos[colorCode]
  const sizeMasterData = moco?.sizes[upc]
  const size = sizeMasterData?.size

  const { t } = useTranslation()

  const [, updateAfaCartProductResult] = useUpdateAfaCartProductsMutation()

  const doors = useSelector(customerDoorsSelector)
  const door = doors.find(door => door.id === doorId)
  const dispatch = useDispatch()
  const deliveryReferences = useSelector(getDeliveryReference)
  const deliveryReferenceValue =
    deliveryReferences[key] !== undefined ? deliveryReferences[key] : reference
  const disableRow = updateAfaCartProductResult.isLoading

  const [newReference, setNewReference] = useState(deliveryReferenceValue)

  useEffect(() => {
    if (deliveryReferences[key]) {
      setNewReference(deliveryReferences[key])
    }
  }, [deliveryReferences, key])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateCart = useCallback(debounce(updateAfaCartProduct, 2000), [])

  const updateReference = (e: React.FormEvent<HTMLInputElement>) => {
    const newReference = e.currentTarget.value
    setNewReference(newReference)
    dispatch(afaCartActions.setDeliveryReferences({ key, value: newReference }))

    if (newReference !== reference) {
      debouncedUpdateCart([
        {
          ...checkoutProduct,
          quantity: checkoutProduct.unconfirmedQuantity,
          reference: newReference,
        },
      ])
    }
  }

  const image = moco
    ? getProductImageUrl({ path: moco.catalogImgPath, imwidth: 300, scale: true })
    : ''

  const hidePlaceholder =
    deliveryReferenceValue === newReference ? deliveryReferenceValue !== '' : newReference !== ''

  return (
    <Wrapper disabled={disableRow} style={style}>
      <ImageIconsWrapper>
        {outOfAssortment && status === '1' && (
          <Tooltip>
            <StyledWarningIcons />
            <span className="tooltip-text">{t('Checkout.Process.OutOfAssortmentWarning')}</span>
          </Tooltip>
        )}

        <ImageIconsAndCode style={{ height: style.height }}>
          <ImageWrapper>
            {!!image && (
              <Image
                src={
                  moco
                    ? getProductImageUrl({ path: moco.catalogImgPath, imwidth: 300, scale: true })
                    : ''
                }
                alt=""
              />
            )}
          </ImageWrapper>

          <Code>
            {modelCode}-{colorCode}-{size}
          </Code>
        </ImageIconsAndCode>
      </ImageIconsWrapper>

      <ProcessTableCell>
        <span>{unconfirmedQuantity}</span>
      </ProcessTableCell>
      <DoorProcessTableCell>
        <span>{doorId}</span> - <ItemDoorName>{door?.name}</ItemDoorName>
      </DoorProcessTableCell>
      <ProcessTableCell>
        <span>{door?.gtmRating}</span>
      </ProcessTableCell>
      <DeliveryDateProcessTableCell>
        <DeliveryDateProcessTableCellContent>
          <BoldCalendarIcon />
          <span>
            {deliveryDate ? format(convertDdMmYyyyToDate(deliveryDate), 'dd/MM/yyyy') : ''}
          </span>
        </DeliveryDateProcessTableCellContent>
      </DeliveryDateProcessTableCell>
      <ProcessTableCell>
        <InputWrapperBodyReference>
          <CustomInput
            type="input"
            value={newReference}
            disabled={unconfirmedQuantity === 0 || outOfAssortment || disableRow}
            onChange={updateReference}
            maxLength={15}
          />
          <LabelInput className={hidePlaceholder ? 'none' : ''}>
            {t('Checkout.Process.ReferencePlaceholder')}
          </LabelInput>
        </InputWrapperBodyReference>
      </ProcessTableCell>
      <ProcessTableCell>
        {unconfirmedQuantity > 0 && (
          <TrashButton
            onClick={() => {
              updateAfaCartProduct([
                {
                  ...checkoutProduct,
                  quantity: 0,
                },
              ])
            }}
          >
            <TrashIconWrapper>
              <TrashIcon />
            </TrashIconWrapper>
          </TrashButton>
        )}
      </ProcessTableCell>
    </Wrapper>
  )
}

export default ProcessTableRow
