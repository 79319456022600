import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { PAGE_SIZES, WhiteboardType } from '../../../model/whiteboard'
import { palette } from '../../../style/theme'
import app_config from '../../../config/app/config'
import { useTranslation } from 'react-i18next'
import { localesUtils } from '../../../libs/locales'

Font.register({
  family: 'GilmerRegular',
  fonts: [{ src: `${app_config.publicUrl}/assets/fonts/gilmer/gilmer-regular.ttf` }],
})

Font.register({
  family: 'GilmerMedium',
  fonts: [{ src: `${app_config.publicUrl}/assets/fonts/gilmer/gilmer-medium.ttf` }],
})

Font.register({
  family: 'GilmerBold',
  fonts: [{ src: `${app_config.publicUrl}/assets/fonts/gilmer/gilmer-bold.ttf` }],
})

Font.registerHyphenationCallback(word => [word])

const paddingPageY = 25
const paddingPageX = 30
const boardMarginTop = 10
const titleFontSize = 14
const borderSize = 1

const boardHeightPdfMax =
  PAGE_SIZES['A4'][0] - paddingPageY * 2 - boardMarginTop - titleFontSize - borderSize * 2
const boardWidthPdfMax = PAGE_SIZES['A4'][1] - paddingPageX * 2 - borderSize * 2

const styles = StyleSheet.create({
  page: {
    padding: `${paddingPageY} ${paddingPageX}`,
    color: palette.tangaroa,
  },
  //use extra wrapper element because 'overflow: hidden' doesn't work on pdf in the same way as on browser and hides border if child item overflows it
  wrapper: {
    marginTop: boardMarginTop,
    border: '1px solid grey',
    position: 'relative',
  },
  board: {
    position: 'relative',
    overflow: 'hidden',
  },
  item: {
    position: 'absolute',
    textAlign: 'center',
    lineHeight: 1.5,
    fontFamily: 'GilmerRegular',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  text: {
    position: 'absolute',
    textAlign: 'center',
    lineHeight: 1.3,
    fontFamily: 'GilmerMedium',
  },
  boardName: {
    fontSize: titleFontSize,
    lineHeight: 1,
  },
})

type Props = {
  whiteboard: WhiteboardType
  images: Record<string, string>
  customerNationality: string
  currency: string
}

interface LabelStyles {
  top: {
    position: 'absolute'
    bottom: number
    textAlign: 'center'
    alignItems: 'center'
    paddingBottom: number
    left: number | 'string'
  }
  bottom: {
    position: 'relative'
    textAlign: 'center'
    alignItems: 'center'
    paddingTop: number
    left: number | 'string'
  }
  right: {
    position: 'absolute'
    right: number | 'string'
    alignItems: 'flex-start'
    paddingLeft: number
  }
  left: {
    position: 'absolute'
    left: number
    textAlign: 'right'
    alignItems: 'flex-end'
    paddingRight: number
  }
}

const chunkSubstr = (str: string, size: number) => {
  const numChunks = Math.ceil(str.length / size)
  const chunks = new Array(numChunks)

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size)
  }

  return chunks
}

const DownloadDocument = ({ whiteboard, images, customerNationality, currency }: Props) => {
  const { t } = useTranslation()

  const width = 1185,
    height = 790
  const ratio = width / height

  const boardWidthCalculated = boardHeightPdfMax * ratio
  const boardHeightCalculated = boardWidthPdfMax / ratio

  const boardWidth =
    boardWidthCalculated > boardWidthPdfMax ? boardWidthPdfMax : boardWidthCalculated
  const boardHeight =
    boardHeightCalculated > boardHeightPdfMax ? boardHeightPdfMax : boardHeightCalculated

  const convertPercentageToPx = (perc: number) => {
    return (perc * boardWidth) / 100
  }

  const minElementWidth = (boardWidth * 100) / width

  const fontSize = boardHeight / 60
  const labelStyle = {
    fontSize: fontSize,
  }

  const labelProductNameStyle = {
    fontSize: fontSize + (fontSize / 100) * 10,
    fontFamily: 'Helvetica-Bold', //Only Helvetica-Bold works for making text bold
  }

  const OOAstyle = {
    fontSize: fontSize,
    color: palette.santasGray,
  }

  Font.registerHyphenationCallback(word => {
    if (word.length > 22) {
      return chunkSubstr(word, 1)
    } else {
      return [word]
    }
  })

  return (
    <Document>
      {whiteboard?.boards.map(
        ({
          key,
          items,
          texts,
          images: importedImages,
          name,
          showProductName,
          showProductNumber,
          showColorName,
          showColorCode,
          showWholesale,
          showSuggested,
          labelPosition,
        }) => (
          <Page key={key} size="A4" orientation="landscape" style={styles.page}>
            <Text style={styles.boardName}>{name}</Text>
            <View
              style={{
                ...styles.wrapper,
                height: boardHeight + borderSize * 2,
                width: boardWidth + borderSize * 2,
              }}
            >
              <View style={{ ...styles.board, height: boardHeight, width: boardWidth }}>
                {items.map(
                  ({
                    id,
                    product: {
                      productName,
                      productNumber,
                      colorName,
                      colorCode,
                      wholesalePrice,
                      suggestedPrice,
                      invalid,
                    },
                    settings,
                  }) => {
                    const pdfImageHeight =
                      convertPercentageToPx(settings.style.height) < minElementWidth
                        ? minElementWidth
                        : convertPercentageToPx(settings.style.height)
                    const pdfImageWidth =
                      convertPercentageToPx(settings.style.width) < minElementWidth
                        ? minElementWidth
                        : convertPercentageToPx(settings.style.width)

                    const labelStyles: LabelStyles = {
                      top: {
                        position: 'absolute',
                        bottom: pdfImageHeight,
                        textAlign: 'center',
                        alignItems: 'center',
                        paddingBottom: 6,
                        left: -pdfImageWidth / 2,
                      },
                      bottom: {
                        position: 'relative',
                        textAlign: 'center',
                        alignItems: 'center',
                        paddingTop: 6,
                        left: -pdfImageWidth / 2,
                      },
                      right: {
                        position: 'absolute',
                        right: -pdfImageWidth * 2,
                        alignItems: 'flex-start',
                        paddingLeft: 6,
                      },
                      left: {
                        position: 'absolute',
                        left: -pdfImageWidth * 2,
                        alignItems: 'flex-end',
                        textAlign: 'right',
                        paddingRight: 6,
                      },
                    }

                    const product = [showProductNumber && productNumber, showColorCode && colorCode]
                      .filter(Boolean)
                      .join(' / ')

                    return (
                      <View
                        key={id}
                        style={{
                          ...styles.item,
                          left: convertPercentageToPx(settings.position.x),
                          top: convertPercentageToPx(settings.position.y),
                          //react-pdf renders zIndex in an opposite mode of browser: element with higher number goes to back and element with lower number goes to front
                          //e.g. element with zIndex = 1 will be in front of zIndex = 2
                          //here is a workaround to reverse it
                          zIndex: 1000000 - settings.style.zIndex,
                        }}
                      >
                        <Image
                          source={images[id]}
                          style={{
                            ...styles.image,
                            width: pdfImageWidth,
                            height: pdfImageHeight,
                            border: '1px solid #f7f7f7',
                          }}
                        />
                        <View
                          style={{
                            ...labelStyles[labelPosition as keyof typeof labelStyles],
                            width: pdfImageWidth * 2,
                          }}
                        >
                          {invalid ? (
                            <Text style={OOAstyle}>
                              {t('Whiteboard.outOfAssortment').toUpperCase()}
                            </Text>
                          ) : (
                            <>
                              {showProductName && (
                                <Text style={labelProductNameStyle}>{productName}</Text>
                              )}
                              {showProductNumber && <Text style={labelStyle}>{product}</Text>}
                              {showColorName && <Text style={labelStyle}>{colorName}</Text>}
                              <View style={{ display: 'flex', flexDirection: 'row' }}>
                                {showWholesale && wholesalePrice !== null && (
                                  <Text
                                    style={{
                                      ...labelStyle,
                                      fontFamily: 'Helvetica-Bold', //Only Helvetica-Bold works for making text bold
                                    }}
                                  >
                                    {localesUtils.formatCurrency(
                                      customerNationality,
                                      wholesalePrice,
                                      currency,
                                    )}
                                  </Text>
                                )}
                                {showSuggested && suggestedPrice !== null && (
                                  <Text style={labelStyle}>
                                    &nbsp;/&nbsp;
                                    {localesUtils.formatCurrency(
                                      customerNationality,
                                      suggestedPrice,
                                      currency,
                                    )}
                                  </Text>
                                )}
                              </View>
                            </>
                          )}
                        </View>
                      </View>
                    )
                  },
                )}
                {texts?.map(({ id, settings, value }) => {
                  return (
                    <Text
                      key={id}
                      style={{
                        ...styles.text,
                        fontSize: convertPercentageToPx(Number(settings.style.fontSize)),
                        left: convertPercentageToPx(settings.position.x),
                        top: convertPercentageToPx(settings.position.y),
                        height: convertPercentageToPx(settings.style.height),
                        width: convertPercentageToPx(settings.style.width),
                        maxWidth: convertPercentageToPx(settings.style.width),
                        //see about zIndex above
                        zIndex: 1000000 - settings.style.zIndex,
                        padding: `${convertPercentageToPx(
                          Number(settings.style.fontSize) / 2,
                        )} ${convertPercentageToPx(Number(settings.style.fontSize) / 1.5)}`,
                      }}
                    >
                      {value}
                    </Text>
                  )
                })}
                {importedImages?.map(({ id, settings, value }) => (
                  <Image
                    key={id}
                    source={value}
                    style={{
                      ...styles.item,
                      left: convertPercentageToPx(settings.position.x),
                      top: convertPercentageToPx(settings.position.y),
                      height: convertPercentageToPx(settings.style.height),
                      width:
                        convertPercentageToPx(settings.style.width) < minElementWidth
                          ? minElementWidth
                          : convertPercentageToPx(settings.style.width),
                      //see about zIndex above
                      zIndex: 1000000 - settings.style.zIndex,
                    }}
                  />
                ))}
              </View>
            </View>
          </Page>
        ),
      )}
    </Document>
  )
}

export default DownloadDocument
