import { StatisticObject } from '../model/analytics'
import { Brand } from '../model/brand'
import { CartProduct } from '../model/cart'
import { ModelCode, Upc } from '../model/model'
import { Moco, Product } from '../model/product'
import { Cluster, Module, StarProduct, StarsAnalyticsData } from '../model/stars'
import { getBrandByBrandCode, isBrandJunior } from './brand'

/** CONSTANTS */
const GENDER_MAN = '1'
const GENDER_WOMAN = '2'
const GENDER_UNISEX = '5'
const CATEGORY_OPTICAL = '1'
const CATEGORY_SUN = '2'
const CATEGORY_ELECTRONICS_OPTICAL = 'E'
const CATEGORY_ELECTRONICS_SUN = 'K'

const statisticsObject: StatisticObject = {
  adv: 0,
  best_seller: 0,
  carry_over: 0,
  color_refresh: 0,
  key: '',
  man: 0,
  models: 0,
  new_model: 0,
  optical: 0,
  polar: 0,
  price_optical: 0,
  price_sun: 0,
  price_total: 0,
  skus_sun: 0,
  skus_optical: 0,
  sun: 0,
  total_items: 0,
  total_new: 0,
  unisex: 0,
  woman: 0,
  junior: 0,
}

export const floatFields = ['price_optical', 'price_sun', 'price_total'] //Fields which values should be rounded

export const createStatisticsObject = () => {
  return { ...statisticsObject }
}

export const roundToPrecision = (number: number, precision: number) =>
  parseFloat(number.toFixed(precision))

/**
 * Aggregates data by summarizing values of each entry in dataObject
 * @param {object} dataObject Source data object
 * @returns {object} Object containing aggregated data for each entry
 */
export const aggregateData = (
  dataObject: Record<string, Record<string, number>>,
): StatisticObject => {
  const result: any = createStatisticsObject()
  Object.keys(dataObject).forEach(dataKey => {
    const statistics = dataObject[dataKey]
    Object.keys(statistics).forEach((field: any) => {
      result[field] += statistics[field] as any
      if (floatFields.includes(field)) {
        result[field] = roundToPrecision((result as any)[field], 2)
      }
    })
  })
  return result as StatisticObject
}

export const isSun = (productDetails: Product) =>
  productDetails.category &&
  (productDetails.category.id === CATEGORY_SUN ||
    productDetails.category.id === CATEGORY_ELECTRONICS_SUN)
export const isOptical = (productDetails: Product) =>
  productDetails.category &&
  (productDetails.category.id === CATEGORY_OPTICAL ||
    productDetails.category.id === CATEGORY_ELECTRONICS_OPTICAL)
export const isMan = (productDetails: Product) =>
  productDetails.gender && productDetails.gender.id === GENDER_MAN
export const isWoman = (productDetails: Product) =>
  productDetails.gender && productDetails.gender.id === GENDER_WOMAN
export const isUnisex = (productDetails: Product) =>
  productDetails.gender && productDetails.gender.id === GENDER_UNISEX

export const getUnitsNumber = (cartProduct: CartProduct, isConditionMet = false) => {
  return isConditionMet ? cartProduct.quantity : 0
}

const isMocoColorRefresh = (moco?: Moco) =>
  (moco?.isNew || moco?.isDigitalPreview) && moco?.newOld?.label === 'A'

const isMocoNew = (moco?: Moco) =>
  (moco?.isNew || moco?.isDigitalPreview) && moco?.newOld?.label === 'N'

const isNewOrColorRefresh = (moco?: Moco) => isMocoColorRefresh(moco) || isMocoNew(moco)

/**
 * Return value to accumulate in statistics object for field
 * Products in cart are already grouped by MOCOs
 * If the field is unique for the whole MOCO (e.g. ADV, CARRY_OVER) it returns 1 or 0
 * Otherwise it returns 'quantity' value
 * @param {object} cartProduct Product object
 * @param {string} field Field name
 * @returns {number} Field statistics value for current moco
 */
export const getFieldValue = (
  cartProduct: CartProduct,
  productDetails: Product,
  field: string,
  updateSkuProcessed?: string[],
  brands: Brand[] = [],
) => {
  const mocoDetails = productDetails.mocos[cartProduct.colorCode]

  if (!mocoDetails) {
    return 0
  }

  const { whsPrice } = mocoDetails
  const { isAdv, isBestseller, isCarryOver, lensProperties } = mocoDetails

  switch (field) {
    case 'adv': {
      return getUnitsNumber(cartProduct, isAdv)
    }
    case 'best_seller': {
      return getUnitsNumber(cartProduct, isBestseller)
    }
    case 'carry_over': {
      return getUnitsNumber(cartProduct, isCarryOver)
    }
    case 'color_refresh': {
      return getUnitsNumber(cartProduct, isMocoColorRefresh(mocoDetails))
    }
    case 'man': {
      return getUnitsNumber(cartProduct, isMan(productDetails))
    }
    case 'new_model': {
      return getUnitsNumber(cartProduct, isMocoNew(mocoDetails))
    }
    case 'optical': {
      return getUnitsNumber(cartProduct, isOptical(productDetails))
    }
    case 'polar': {
      return getUnitsNumber(cartProduct, lensProperties ? lensProperties.label == 'PL' : false)
    }
    case 'price_optical': {
      return isOptical(productDetails) && whsPrice !== null ? whsPrice * cartProduct.quantity : 0
    }
    case 'price_sun': {
      return isSun(productDetails) && whsPrice !== null ? whsPrice * cartProduct.quantity : 0
    }
    case 'price_total': {
      return whsPrice !== null ? whsPrice * cartProduct.quantity : 0
    }
    case 'skus_sun': {
      return isSun(productDetails) && !updateSkuProcessed ? 1 : 0
    }
    case 'skus_optical': {
      return isOptical(productDetails) && !updateSkuProcessed ? 1 : 0
    }
    case 'sun': {
      return getUnitsNumber(cartProduct, isSun(productDetails))
    }
    case 'total_items': {
      return cartProduct.quantity
    }
    case 'total_new': {
      return getUnitsNumber(cartProduct, isNewOrColorRefresh(mocoDetails))
    }
    case 'unisex': {
      return getUnitsNumber(cartProduct, isUnisex(productDetails))
    }
    case 'woman': {
      return getUnitsNumber(cartProduct, isWoman(productDetails))
    }
    case 'junior': {
      const isJunior = isBrandJunior(brands, productDetails.brandCode)
      return getUnitsNumber(cartProduct, isJunior)
    }
    default: {
      return 0
    }
  }
}

// TODO: left to keep compatibility with Header component
export const generateDougnutData = (
  label: string,
  source1: { header: string; values: string },
  source2 = source1,
) => {
  let backgroundColor, borderColor, hoverBackgroundColor

  const generateGradient = (r: number, g: number, b: number) => [
    `rgba(${r}, ${g}, ${b}, 0.4)`,
    `rgba(${r}, ${g}, ${b}, 0.6)`,
    `rgba(${r}, ${g}, ${b}, 0.7)`,
    `rgba(${r}, ${g}, ${b}, 0.8)`,
  ]
  const rgba = (r: number, g: number, b: number, a: number) => `rgba(${r}, ${g}, ${b}, ${a})`
  const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

  const datasetLabel = `Units for price - ${capitalize(label)}`
  switch (label) {
    case 'optical':
      backgroundColor = generateGradient(82, 187, 234)
      borderColor = Array(source2.values.length).fill(rgba(82, 187, 234, 0.4))
      hoverBackgroundColor = Array(source2.values.length).fill(rgba(82, 187, 234, 1))
      break

    case 'sun':
      backgroundColor = generateGradient(0, 81, 146)
      borderColor = Array(source2.values.length).fill(rgba(0, 81, 146, 0.4))
      hoverBackgroundColor = Array(source2.values.length).fill(rgba(0, 81, 146, 1))
      break
  }

  return {
    labels: source1.header,
    datasets: [
      {
        label: datasetLabel,
        data: source1.values,
        backgroundColor,
        borderColor,
        hoverBackgroundColor,
        borderWidth: 1,
      },
    ],
  }
}

export const calculateAnalytics = ({
  cartProducts,
  brands,
  cartProductsDetails,
}: {
  cartProducts: CartProduct[]
  brands: Brand[]
  cartProductsDetails: Record<string, Product>
}) => {
  const ignoredFields = ['models'] //Fields that should be calculated in a special way

  const analytics: { brands: Record<string, any>; total?: StatisticObject } = { brands: {} }
  const modelCodes: string[] = []
  const upcsProcessed: Record<string, any> = {}

  cartProducts.forEach(cartProduct => {
    const { modelCode, brandCode, upc } = cartProduct
    const brand = getBrandByBrandCode(brands, brandCode)
    const productDetails = cartProductsDetails[modelCode]

    if (!productDetails) {
      return
    }

    if (brand?.code && !analytics.brands[brand.code]) {
      analytics.brands[brand.code] = createStatisticsObject()
    }

    if (upc && upcsProcessed[upc] === undefined) {
      upcsProcessed[upc] = false
    }

    const brandAnalytics = analytics.brands[brand?.code || '']

    Object.keys(brandAnalytics).forEach(key => {
      if (ignoredFields.includes(key)) {
        return
      }
      brandAnalytics[key] += getFieldValue(
        cartProduct,
        productDetails,
        key,
        upcsProcessed[upc],
        brands,
      )
      if (floatFields.includes(key)) {
        brandAnalytics[key] = roundToPrecision(brandAnalytics[key], 2)
      }
    })

    upcsProcessed[upc] = true

    if (!modelCodes.includes(modelCode)) {
      modelCodes.push(modelCode)
      brandAnalytics.models++
    }
  })

  // analytics.currency = products.length ? products[0].product.currency : ''
  analytics.total = aggregateData(analytics.brands)
  analytics.total.key = 'TOTAL'
  analytics.brands = Object.keys(analytics.brands)
    .sort()
    .map(code => {
      const brandAnalytics = analytics.brands[code]
      brandAnalytics.key = code
      return brandAnalytics
    })

  return analytics
}

export const calculateStarsAnalytics = (
  starsProducts: StarProduct[],
  productsDetails: Record<ModelCode, Product>,
  modules: Module[],
  clusters: Cluster[],
  activeDoorsIds: string[],
): StarsAnalyticsData => {
  const clusterOnActiveDoors = clusters.filter(({ doorsIds }) =>
    doorsIds.some(doorId => activeDoorsIds.includes(doorId)),
  )
  const modulesOnActiveDoors = modules.filter(({ id, month }) =>
    clusterOnActiveDoors.some(
      cluster => cluster.month === month && cluster.modulesIds.includes(id),
    ),
  )
  const upcOnActiveDoorsMap = modulesOnActiveDoors.reduce((result, { productsUpcs }) => {
    productsUpcs.forEach(upc => {
      result[upc] = true
    })
    return result
  }, {} as Record<Upc, true>)
  const starsProductsOnActiveDoors = starsProducts.filter(({ upc }) => upcOnActiveDoorsMap[upc])

  const baseAnalytics = starsProductsOnActiveDoors.reduce(
    (result, { modelCode, colorCode }) => {
      const model = productsDetails[modelCode]
      const moco = model.mocos[colorCode]
      if (moco?.isNew) {
        result.newProducts++
      }
      if (moco?.isDigitalPreview) {
        result.digitalPreview++
      }
      if (isMocoColorRefresh(moco)) {
        result.colorRefresh++
      }
      if (isNewOrColorRefresh(moco)) {
        result.totalNew++
      }
      if (moco?.isCarryOver) {
        result.carryOver++
      }
      if (model?.gender?.id === '1') {
        result.man++
      }
      if (model?.gender?.id === '2') {
        result.woman++
      }
      if (model?.gender?.id === '5') {
        result.unisex++
      }
      if (moco?.lensProperties?.id.toLowerCase() === 'pl') {
        result.polar++
      }
      const frontMaterial = model.frontMaterial?.label.toLowerCase()
      if (frontMaterial) {
        result.materials[frontMaterial] = result.materials[frontMaterial]
          ? result.materials[frontMaterial] + 1
          : 1
      }

      return result
    },
    {
      carryOver: 0,
      newProducts: 0,
      colorRefresh: 0,
      digitalPreview: 0,
      totalNew: 0,
      man: 0,
      woman: 0,
      unisex: 0,
      polar: 0,
      materials: {} as Record<string, number>,
    },
  )

  const skus = starsProductsOnActiveDoors.length

  const eligibleUpcs = modulesOnActiveDoors
    .filter(({ name }) => name === 'ELIGIBLE')
    .flatMap(({ productsUpcs }) => productsUpcs)

  const numberOfSkusInEligibleModules = [...new Set(eligibleUpcs)].length

  return {
    ...baseAnalytics,
    skus,
    mh: skus - numberOfSkusInEligibleModules,
    clustersDoors:
      [...new Set(clusterOnActiveDoors.flatMap(({ doorsIds }) => doorsIds))].length || 0,
    activeDoors: activeDoorsIds.length,
  }
}
