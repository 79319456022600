import { useCallback, useMemo } from 'react'
import { getUnavailableKeys } from '../libs/afa'
import { AfaCartProduct } from '../model/afa'
import { useGetExpiredCartProducts } from './useGetExpiredCartProducts'
import { useSelector } from 'react-redux'
import { afaCartSelectors } from '../store/afaCart/selectors'
import { useGetAfaCartProducts } from './useGetAfaCartProducts'

const useAfaGetCartUnavailableKeys = (cartProducts?: AfaCartProduct[]) => {
  const drops = useSelector(afaCartSelectors.dropsRealtime) || undefined
  const cartProductsQuery = useGetAfaCartProducts()
  const { expiredSeasonsCartProducts } = useGetExpiredCartProducts()
  const expiredSeasonsCartProductsUpcs = expiredSeasonsCartProducts.map(({ key }) => key)

  const unavailableKeys = useMemo(() => {
    const cartProductsToCheck =
      cartProducts === undefined ? cartProductsQuery.cartProducts : cartProducts
    return getUnavailableKeys(cartProductsToCheck, drops).concat(expiredSeasonsCartProductsUpcs)
  }, [cartProducts, cartProductsQuery, drops, expiredSeasonsCartProductsUpcs])

  const checkUnavailableCartProducts = useCallback(
    (cartProducts: AfaCartProduct[]) => {
      const cartProductsUpcsWithQuantity = cartProducts
        .filter(({ unconfirmedQuantity }) => unconfirmedQuantity > 0)
        .map(({ upc }) => upc)
      const expiredSeasonsCartProductsWithQuantity = expiredSeasonsCartProductsUpcs.filter(upc =>
        cartProductsUpcsWithQuantity.includes(upc),
      )
      return getUnavailableKeys(cartProducts, drops).concat(expiredSeasonsCartProductsWithQuantity)
    },
    [drops, expiredSeasonsCartProductsUpcs],
  )

  return { unavailableKeys, checkUnavailableCartProducts }
}

export default useAfaGetCartUnavailableKeys
