import '@fortawesome/fontawesome-free/css/all.css'

import { AfaCartProduct, UpdateAfaCartProductPayload } from '../../../model/afa'
import {
  CartProductsListRow,
  CustomInput,
  LabelInput,
} from '../components/AfaCheckoutCommonComponents'
import React, { useEffect, useMemo, useState } from 'react'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  getFluidSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../../style/theme'
import {
  useGetAfaFilteredCheckoutProducts,
  useGetFilteredAfaCartQuery,
  useUpdateAfaCartProductsMutation,
} from '../../../services/afaCart'

import AutoSizer from 'react-virtualized-auto-sizer'
import { Door } from '../../../model/customer'
import { FixedSizeList as List } from 'react-window'
import Loading from '../../../components/Loading'
import { Modal } from 'antd'
import ProcessTableRow from './ProcessTableRow'
import SortActiveIcon from '../../../components/icons/SortActiveIcon'
import SortRestingIcon from '../../../components/icons/SortRestingIcon'
import { customerDoorsSelector } from '../../../store/customer/selectors'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CloseIcon from '../../../components/icons/CloseIcon'
import {
  errorNotification,
  successNotification,
} from '../../../components/Notification/notifications'
import afaCartActions from '../../../store/afaCart/actions'
import { convertDdMmYyyyToDate } from '../../../libs/time'

const ProcessWrapper = styled.div`
  height: 100%;
  padding: 0 2vw;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`

const CartProductsList = styled.div`
  width: 100%;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: ${palette.biscay};
  font-size: ${getFluidFontSize('12px', 15)};
  font-weight: bold;
`

const ListHead = styled(CartProductsListRow)`
  font-size: ${getFluidFontSize('12px', 15)};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid ${palette.platinum};
  background-color: ${palette.white};
`

const ListLabels = styled(CartProductsListRow)`
  border-bottom: ${pxToRem(spacing(0.2))}rem solid;
  border-color: ${palette.geyser};
  background: ${palette.white};
  font-size: ${getFluidFontSize('14px', 20)};
  border-left: 1px solid ${palette.platinum};
  border-right: 1px solid ${palette.platinum};
`

const InputReferenceListCell = styled.div`
  grid-column-start: 6;
`

const ListCell = styled.div``

const ListBody = styled.div`
  flex: 1;
`

const NumberOfRowsCell = styled.div`
  margin: ${pxToRem(spacing(1.5))}rem 0;
  display: flex;
  color: ${palette.biscay};
  padding: ${getFluidSize(`${pxToRem(10)}rem`, 15)} ${getFluidSize(`${pxToRem(10)}rem`, 20)};
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: ${pxToRem(spacing(0.5))}rem;
  font-size: ${getFluidFontSize(`${pxToRem(12.5)}rem`, 15)};

  span:first-of-type {
    text-transform: uppercase;
  }
`

const ListHeaderCell = styled.div`
  display: flex;
  color: ${palette.manatee};
  margin: ${getFluidSize(`${pxToRem(15)}rem`, 15)} 0;
  align-items: center;
  text-transform: uppercase;
  flex-direction: row;
  gap: ${pxToRem(spacing(1))}rem;
  cursor: pointer;

  &.center {
    justify-content: center;
  }

  &.start {
    justify-content: start;
  }

  &.end {
    justify-content: end;
  }
`

const ColumnLabel = styled.span`
  font-size: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
`

const ArrowWrapper = styled.div`
  display: flex;
  color: ${palette.hibiscus};
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  flex-direction: column;
  width: clamp(8px, ${getFluidSizeWithFullFormula('px', 8, 14.5, 1366, 3840)}, 14.5px);

  .anticon {
    height: 1.5em;
    width: 1.5em;
    cursor: pointer;
  }
`

const InputWrapperHeadReference = styled.div`
  border: solid 1px ${palette.shadowBlue};
  border-radius: ${pxToRem(spacing(0.5))}rem;
  background: ${palette.white};
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: ${pxToRem(8)}rem ${pxToRem(12)}rem;

  font-size: clamp(10px, ${getFluidSizeWithFullFormula('px', 10, 24, 1366, 3840)}, 24px);
  letter-spacing: 0.3px;
  font-weight: bold;

  label {
    color: ${palette.shadowBlue};
  }
`

const ApplyButton = styled.button`
  background: ${palette.extraLight};
  border-radius: 4px;
  text-transform: uppercase;
  padding: ${getFluidSize(`${pxToRem(5)}rem`, 15)} ${getFluidSize(`${pxToRem(30)}rem`, 15)};
  color: ${palette.tangaroa};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${palette.concrete};

  [disabled] {
    opacity: 0.5;
    pointer-events: none;
    color: ${palette.alto};
  }
`

const WrapperBodyApply = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const BodyApplyText = styled.p`
  text-align: left;
  font-weight: bold;
  font-size: ${getFluidSizeWithFullFormula('px', 20, 40, 1366, 3840)};
  color: ${palette.tangaroa};

  margin-top: ${getFluidSizeWithFullFormula('px', 72, 130, 1366, 3840)};
  margin-bottom: ${getFluidSizeWithFullFormula('px', 96, 150, 1366, 3840)};
`

const WrapperButtonBodyApply = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${getFluidSizeWithFullFormula('px', 40, 64, 1366, 3840)};
`

const ButtonAction = styled.button`
  text-align: center;
  text-transform: uppercase;
  font-size: ${getFluidSizeWithFullFormula('px', 13, 24, 1366, 3840)};
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 1.38;
  min-height: ${getFluidSizeWithFullFormula('px', 56, 72, 1366, 3840)};
  border: 1px solid ${palette.tangaroa};

  @media (min-width: ${breakpoints.L}) {
    line-height: 0.75;
    border-width: 2px;
  }
`

const ButtonBodyApplyCancel = styled(ButtonAction)`
  color: ${palette.tangaroa};
  min-width: ${getFluidSizeWithFullFormula('px', 169, 150, 1366, 3840)};
`

const ButtonBodyApplyConfirm = styled(ButtonAction)`
  background: ${palette.tangaroa};
  color: ${palette.white};
  min-width: ${getFluidSizeWithFullFormula('px', 169, 279, 1366, 3840)};
`

const CloseModalButton = styled.button`
  position: absolute;
  right: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};

  @media (max-width: ${breakpointsCross.L.max}) {
    top: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};
  }

  @media (min-width: ${breakpoints.L}) {
    bottom: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};
  }

  width: ${getFluidSizeWithFullFormula('px', 24, 48, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 24, 48, 1366, 3840)};

  svg {
    width: ${getFluidSizeWithFullFormula('px', 27.79, 55.58, 1366, 3840)};
    height: ${getFluidSizeWithFullFormula('px', 27.79, 55.58, 1366, 3840)};
    fill: ${palette.tangaroa};
  }
`

const WrapperNoProducts = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${palette.white};
  font-size: ${getFluidFontSize('14px', 15)};
  text-transform: uppercase;
  color: ${palette.tangaroa};
  height: 100%;
  align-items: center;

  span {
    padding: ${pxToRem(spacing(2))}rem;
    font-weight: bold;
  }
`

const SortRestingIconStyled = styled(SortRestingIcon)`
  width: clamp(8px, ${getFluidSizeWithFullFormula('px', 8, 14.5, 1366, 3840)}, 14.5px);
  height: clamp(11px, ${getFluidSizeWithFullFormula('px', 11, 20, 1366, 3840)}, 20px);
`

const isOutOfAssortment = (product: AfaCartProduct) => {
  return product.outOfAssortment && product.status === '1'
}

const sortByOutOfAssortment = (p1: AfaCartProduct, p2: AfaCartProduct) => {
  if (isOutOfAssortment(p1) && !isOutOfAssortment(p2)) {
    return -1
  } else if (isOutOfAssortment(p2) && !isOutOfAssortment(p1)) {
    return 1
  } else {
    return 0
  }
}

const sortProducts = (
  products: AfaCartProduct[] | undefined,
  sortInfo: SortInfo,
  doors: Door[],
) => {
  if (!products) {
    return undefined
  }

  return products.slice().sort((p1, p2) => {
    const order = sortInfo.order === 'ASC' ? 1 : -1

    const sortByValue = () => {
      if (sortInfo.key === 'deliveryDate') {
        const firstDate = (p1.deliveryDate && convertDdMmYyyyToDate(p1.deliveryDate).getTime()) || 0
        const secondDate =
          (p2.deliveryDate && convertDdMmYyyyToDate(p2.deliveryDate).getTime()) || 0
        if (firstDate > secondDate) {
          return 1
        } else if (firstDate < secondDate) {
          return -1
        } else {
          return 0
        }
      }

      if (sortInfo.key === 'gtm') {
        const firstValue = doors.find(({ id }) => id === p1.doorId)?.gtmRating ?? '9999999999999' // if nullish it goes last
        const secondValue = doors.find(({ id }) => id === p2.doorId)?.gtmRating ?? '9999999999999' // if nullish it goes last
        return firstValue.localeCompare(secondValue)
      }

      const firstValue = p1[sortInfo.key]
      const secondValue = p2[sortInfo.key]
      if (!firstValue || !secondValue) {
        return 0
      } else if (typeof firstValue === 'number' && typeof secondValue === 'number') {
        return firstValue - secondValue
      } else {
        return firstValue.toString().localeCompare(secondValue.toString())
      }
    }

    return (sortByOutOfAssortment(p1, p2) || sortByValue()) * order
  })
}

type TableFields = Partial<AfaCartProduct> & { gtm: 'BP' | 'BPF' | 'P' | 'PF' | 'D' }

type SortInfo = {
  key: keyof TableFields
  order: 'ASC' | 'DESC'
}

const SortActiveIconStyled = styled(SortActiveIcon)`
  width: clamp(11.64px, ${getFluidSizeWithFullFormula('px', 11.64, 21.1, 1366, 3840)}, 21.1px);
`

const Row = ({
  data: { cartProducts, updateAfaCartProduct },
  index,
  style,
}: {
  data: {
    cartProducts: AfaCartProduct[]
    updateAfaCartProduct: ReturnType<typeof useUpdateAfaCartProductsMutation>[0]
  }
  index: number
  style: Record<string, any>
}) => {
  const cartProduct = cartProducts[index]
  return (
    <ProcessTableRow
      style={style}
      key={`${cartProduct.upc}-${cartProduct.doorId}-${cartProduct.status}-${cartProduct.unconfirmedQuantity}-${index}`}
      checkoutProduct={cartProduct}
      updateAfaCartProduct={updateAfaCartProduct}
    />
  )
}

const ProcessTable: React.FC = () => {
  const { t } = useTranslation()

  const cartQueryFiltered = useGetFilteredAfaCartQuery()
  const [filteredCheckoutProducts] = useGetAfaFilteredCheckoutProducts()
  const productsInCartFiltered = cartQueryFiltered.data?.items

  const [updateAfaCartProduct, updateAfaCartProductResult] = useUpdateAfaCartProductsMutation()
  const { isLoading, status, isError } = updateAfaCartProductResult

  const [needNotificationStatusCheck, setNeedNotificationStatusCheck] = useState(false)

  useEffect(() => {
    if (!needNotificationStatusCheck) {
      return
    }

    if (isError) {
      errorNotification({
        message: t('Afa.errorUpdateReferences'),
      })
      setNeedNotificationStatusCheck(false)
    }
  }, [t, isError, needNotificationStatusCheck])

  const dispatch = useDispatch()

  useEffect(() => {
    if (!needNotificationStatusCheck) {
      return
    }

    if (!isLoading && status === 'fulfilled') {
      successNotification({
        message: t('Checkout.Process.ReferencesUpdated'),
      })
      setNeedNotificationStatusCheck(false)
    }
  }, [isLoading, needNotificationStatusCheck, status, t])

  useEffect(() => {
    return () => {
      dispatch(afaCartActions.clearDeliveryReferences())
    }
  }, [dispatch])

  const [reference, setReference] = useState<string | undefined>('')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isSortingResting, setIsSortingResting] = useState(true)
  const [sortInfo, setSortInfo] = useState<SortInfo>({ key: 'modelCode', order: 'ASC' })

  const doors = useSelector(customerDoorsSelector)
  const sortedProducts = useMemo(() => sortProducts(productsInCartFiltered, sortInfo, doors), [
    productsInCartFiltered,
    sortInfo,
    doors,
  ])

  const itemsData = useMemo(() => {
    return { updateAfaCartProduct, cartProducts: sortedProducts || [] }
  }, [updateAfaCartProduct, sortedProducts])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setReference('')
    setIsModalVisible(false)
  }

  type JustifyContent = 'start' | 'center' | 'end'

  const tableHeadArr: [string, keyof TableFields, JustifyContent][] = [
    [t('Checkout.Process.Sku'), 'modelCode', 'center'],
    [t('Checkout.Process.Quantity'), 'unconfirmedQuantity', 'center'],
    [t('Checkout.Process.Door'), 'doorId', 'center'],
    [t('Checkout.Process.GTM'), 'gtm', 'center'],
    [t('Checkout.Process.DeliveryDate'), 'deliveryDate', 'center'],
    [t('Checkout.Process.ReferenceNumber'), 'reference', 'start'],
  ]

  return (
    <ProcessWrapper>
      <CartProductsList>
        <ListHead>
          <NumberOfRowsCell>
            <span>{t('Checkout.Process.Rows')}:</span>
            <span>{productsInCartFiltered?.length}</span>
            <span>of</span>
            <span>{filteredCheckoutProducts?.length}</span>
          </NumberOfRowsCell>
          <InputReferenceListCell>
            <InputWrapperHeadReference>
              <CustomInput
                type="input"
                value={reference}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setReference(e.currentTarget.value)
                }
                maxLength={15}
                disabled={updateAfaCartProductResult.isLoading}
              />
              <LabelInput className={reference === '' ? '' : 'none'}>
                {t('Checkout.Process.ReferencePlaceholder')}
              </LabelInput>
            </InputWrapperHeadReference>
          </InputReferenceListCell>
          <ListCell>
            <ApplyButton
              onClick={showModal}
              disabled={reference === '' || updateAfaCartProductResult.isLoading}
            >
              {t('Checkout.Process.ApplyDate')}
            </ApplyButton>
          </ListCell>
        </ListHead>
        <ListLabels>
          {tableHeadArr.map((column, index) => {
            const key = column[1]
            const columnName: string = column[0].toString()
            return (
              <ListHeaderCell
                key={index}
                className={column[2]}
                onClick={() => {
                  setSortInfo({
                    key,
                    order:
                      sortInfo.key === key
                        ? sortInfo.order === 'ASC'
                          ? 'DESC'
                          : 'ASC'
                        : sortInfo.order,
                  })
                  if (isSortingResting) {
                    setIsSortingResting(false)
                  }
                }}
              >
                <ColumnLabel>{columnName}</ColumnLabel>
                <ArrowWrapper>
                  {sortInfo.key === key && !isSortingResting ? (
                    <SortActiveIconStyled order={sortInfo.order} fill={palette.manatee} />
                  ) : (
                    <SortRestingIconStyled />
                  )}
                </ArrowWrapper>
              </ListHeaderCell>
            )
          })}
        </ListLabels>

        <ListBody>
          {sortedProducts ? (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  itemCount={sortedProducts.length}
                  itemSize={Math.round(height / (width > height ? 5 : 8.5))}
                  itemData={itemsData}
                  width={width}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          ) : (
            <Loading />
          )}

          {productsInCartFiltered && !productsInCartFiltered.length && (
            <WrapperNoProducts>
              <span>{t('Checkout.Process.NoProducts')}</span>
            </WrapperNoProducts>
          )}
        </ListBody>
      </CartProductsList>

      <Modal
        className="modal-afa-checkout-apply"
        wrapClassName="checkout-modal-wrapper-apply"
        title={t('Checkout.Process.ApplyReferenceTitle')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={null}
        width="70vw"
        closable={false}
      >
        <CloseModalButton onClick={handleCancel}>
          <CloseIcon />
        </CloseModalButton>
        <WrapperBodyApply>
          <BodyApplyText>
            {t('Checkout.Process.ApplyReferenceWarning', { reference: reference?.toUpperCase() })}
          </BodyApplyText>
          <WrapperButtonBodyApply>
            <ButtonBodyApplyCancel onClick={handleCancel}>
              {t('Checkout.ImportExport.ModalCancel')}
            </ButtonBodyApplyCancel>
            <ButtonBodyApplyConfirm
              onClick={() => {
                if (!productsInCartFiltered) {
                  return
                }

                setNeedNotificationStatusCheck(true)

                const products: UpdateAfaCartProductPayload[] = productsInCartFiltered
                  .filter(({ status, outOfAssortment }) => {
                    return status === '1' && !outOfAssortment
                  })
                  .map(product => {
                    return {
                      ...product,
                      quantity: product.unconfirmedQuantity,
                      requestStatus: 'waiting' as const,
                      reference: reference || product.reference || undefined,
                    }
                  })
                updateAfaCartProduct(products)
                products.forEach(product => {
                  dispatch(
                    afaCartActions.setDeliveryReferences({
                      key: product.key || '',
                      value: reference || product.reference || '',
                    }),
                  )
                })

                handleCancel()
              }}
            >
              {t('Checkout.Process.ApplyReferenceConfirm')}
            </ButtonBodyApplyConfirm>
          </WrapperButtonBodyApply>
        </WrapperBodyApply>
      </Modal>
    </ProcessWrapper>
  )
}

export default ProcessTable
