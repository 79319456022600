import { Menu } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { PricePrefs } from '../../../model/customer'
import {
  changeLanguage,
  closeFooterModal,
  logout,
  setShowNotes,
  updatePricePrefs,
} from '../../../store/actions'
import {
  isKamUserSelector,
  languageSelector,
  languagesSelector,
} from '../../../store/app/selectors'
import { customerSelector } from '../../../store/customer/selectors'
import { resetForNewClient } from '../../../store/extraActions'
import { getFluidFontSize, getFluidSizeWithFullFormula, palette } from '../../../style/theme'
import { OnOffToggle } from '../../../styleguide/OnOffToggle'
import PageSocketManager, { PageSocketManagerProps } from '../../PageSocketManager'
import GotoCheckoutButton from '../FooterCheckout'
import FooterLoginLanguageMenu from './FooterLoginLanguageMenu'
import FooterLoginPricePrefs from './FooterLoginPricePrefs'
import SubMenu from './FooterLoginSubMenu/FooterLoginSubMenu'
import { starsBrandsSelector } from '../../../store/brands/selectors'
import config from '../../../config/app/config'

export const StyledMenuItem = styled(Menu.Item)`
  font-size: ${getFluidFontSize('1rem')} !important;
  padding: 0;
  margin: 0;
  > a {
    color: #005192;
    padding: 20px 0;
  }
`
export const MenuWithSubMenu = styled(StyledMenuItem)`
  position: initial !important;
`

const LoginMenu = styled(Menu as any)`
  z-index: 1001;
  &::before {
    content: none;
  }
  .ant-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dadada;
    font-size: 1.1rem;
    color: #005192;
    line-height: 1.2;
    height: auto;
    margin: 0 !important;
    text-align: left;
    overflow: visible;
    padding-left: 0;
    padding-right: 0;
    &-selected {
      background: white;
    }
    .anticon {
      margin-right: 0;
    }
    &::after,
    &::before {
      content: none;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &.afa {
    .ant-menu-item,
    .ant-menu-item > a {
      font-family: GilmerRegular, sans-serif;
      color: ${palette.tangaroa};
    }

    .price-prefs span {
      font-family: GilmerRegular, sans-serif;
      color: ${palette.tangaroa};
    }
  }
`

const WhiteboardLink = styled.div`
  height: ${getFluidSizeWithFullFormula('px', 61, 74, 1366, 3840)};
  line-height: ${getFluidSizeWithFullFormula('px', 61, 74, 1366, 3840)};
  width: 100%;
`

type OwnProps = {
  className?: string
}

type Props = OwnProps & PageSocketManagerProps

export const FooterLogin: React.FC<Props> = ({ emitEvent, className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const url = window.location.href
  const isAfa = url.includes('afa')
  const match = url.match(/\/whiteboard\/([^/]+)/)
  const isSingleWhiteboardPage = !!match && !!match[1]

  const starsBrands = useSelector(starsBrandsSelector)
  const languages = useSelector(languagesSelector)
  const lang = useSelector(languageSelector)
  const customer = useSelector(customerSelector)
  const isKamUser = useSelector(isKamUserSelector)

  const changeLanguages = (langCode: string) => {
    emitEvent('change_language', { langCode })
    dispatch(changeLanguage(langCode))
    dispatch(closeFooterModal())
  }

  const onChangePricePrefs = (pricePrefs: PricePrefs) => {
    dispatch(updatePricePrefs(pricePrefs))
    emitEvent('update_price_prefs', { pricePrefs })
  }

  const renderPricesSubmenu = () => {
    return (
      (customer.pricePreferencesLoadingStatus === 'ok' ||
        customer.pricePreferencesLoadingStatus === 'ko') && (
        <MenuWithSubMenu key="3">
          <SubMenu
            renderMenu={(goBack: () => void) => (
              <FooterLoginPricePrefs
                customer={customer}
                goBack={goBack}
                onChangePricePrefs={onChangePricePrefs}
              />
            )}
          >
            {t('FooterNav.price_settings')}
          </SubMenu>
        </MenuWithSubMenu>
      )
    )
  }

  return (
    <div className={[className, 'footerLogin'].join(' ')}>
      <LoginMenu className={isAfa ? 'afa' : ''}>
        <MenuWithSubMenu key="1">
          <SubMenu
            className="login-menu-content"
            renderMenu={(goBack: () => void) => (
              <FooterLoginLanguageMenu
                lang={lang}
                languages={languages || []}
                goBack={goBack}
                onLanguageChanged={changeLanguages}
              />
            )}
          >
            {t('FooterNav.changeLanguage')}
          </SubMenu>
        </MenuWithSubMenu>

        {!isAfa && (
          <StyledMenuItem key="2">
            <div className="sub-menu">
              {t('FooterNav.showNotes')}
              <OnOffToggle checked={customer.showNotes} onChange={() => dispatch(setShowNotes())} />
            </div>
          </StyledMenuItem>
        )}

        {isKamUser && renderPricesSubmenu()}

        {isKamUser && !isAfa && starsBrands.length > 0 && (
          <StyledMenuItem key="4">
            <a
              href={config.andromedaUrl}
              target="_blank"
              rel="noreferrer"
            >
              {t('FooterNav.andromeda')}
            </a>
          </StyledMenuItem>
        )}

        {isKamUser && !isAfa && (
          <StyledMenuItem key="5">
            <GotoCheckoutButton showButtonAsLink={true} />
          </StyledMenuItem>
        )}

        <StyledMenuItem key="6">
          {isSingleWhiteboardPage ? (
            <WhiteboardLink id="customer-list-link">{t('CustomersList.title')}</WhiteboardLink>
          ) : (
            <Link onClick={() => dispatch(resetForNewClient())} to="/customers">
              {t('CustomersList.title')}
            </Link>
          )}
        </StyledMenuItem>

        <StyledMenuItem key="7">
          <button
            className="sub-menu"
            onClick={() => {
              dispatch(logout())
            }}
          >
            {t('FooterNav.menu_logout')}
          </button>
        </StyledMenuItem>
      </LoginMenu>
    </div>
  )
}

export default PageSocketManager(FooterLogin)
